import React, { useEffect, useState } from 'react'
import { Space, Table, } from 'antd';
import { EditFilled, DeleteFilled } from '@ant-design/icons'
import dayjs from 'dayjs'
import Component_edit from './editeducationEmployee';
import Component_delete from './deleteeducationEmployee';
export default function TableEducationEmployee(props) {
    const [datas, setDatas] = useState([]);
    const [isLoading, setIsloading] = useState(true);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [objOne, setObjone] = useState({});
    const columns = [
        {
            title: 'No.',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Major Subject',
            dataIndex: 'branch',
            key: 'branch',
        },
        {
            title: 'Education Institutes',
            dataIndex: 'institution',
            key: 'institution',
        },
        {
            title: 'Level',
            dataIndex: 'educationId',
            key: 'educationId',
            render: (text, record) => {
                return record.education.name
            }
        },
        {
            title: 'G.P.A',
            dataIndex: 'score',
            key: 'score',
        },
        {
            title: 'Period',
            dataIndex: 'period',
            key: 'period',
            render: (text, record) => {
                if (record.congratulationsDate && record.studyDate) {
                    return dayjs(record.studyDate).format('MMM YYYY') + ' - ' + dayjs(record.congratulationsDate).format('MMM YYYY')
                } else {
                    return '-'
                }
            }
        },
        {
            title: 'Manage',
            key: 'manage',
            render: (_, record) => (
                <Space size="middle">
                    <EditFilled onClick={() => { setObjone(record); setModalEditOpen(true) }} />
                    <DeleteFilled onClick={() => { setObjone(record); setModalDeleteOpen(true) }} />
                </Space>
            ),
        },
    ];
    useEffect(() => {
        setDatas(props.data.education_employee_maps.map((e, i) => { return { ...e, 'key': i + 1 } }));
        setIsloading(props.isLoading)
        return () => {
            // cleanup
        };
    }, [props]);

    return (
        <>
            <Table loading={isLoading} columns={columns} dataSource={datas} />
            <Component_edit
                handleUpdated={() => { props.handleUpdated() }}
                datasEducation={props.datasEducation}
                data={Object.assign({ 'name': props.data.firstname_th + " " + props.data.lastname_th }, objOne)}
                modalOpen={modalEditOpen}
                onClose={() => {
                    setModalEditOpen(false);
                }}
            />
            <Component_delete
                handleUpdated={() => { props.handleUpdated() }}
                data={Object.assign({ 'name': props.data.firstname_th + " " + props.data.lastname_th }, objOne)}
                modalOpen={modalDeleteOpen}
                onClose={() => {
                    setModalDeleteOpen(false);
                }}
            />
        </>
    )
}
