import { Excel } from "antd-table-saveas-excel";
import dayjs from 'dayjs';
export default function handlePrint(dataTable) {
    const excel = new Excel();
    const columns = [
        {
            title: 'No.',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Used',
            dataIndex: 'used',
            key: 'used',
            render: (_, record) => {
                return (_ / 24)
            },
        },
        {
            title: 'Max',
            dataIndex: 'max',
            key: 'max',
            render: (_, record) => {
                return (_ / 24)
            },
        },
        {
            title: 'Type',
            dataIndex: 'typeleaveId',
            key: 'typeleaveId',
            render: (_, record) => {
                return record['typeleave'].name
            },
        },
        {
            title: 'Year',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, record) => {
                return dayjs(_).format('YYYY')
            },
            sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        },
    ];
    excel
        .addSheet("sheet 1")
        .addColumns(columns)
        .addDataSource(dataTable.map((d, i) => { return { ...d, key: i+1 } }), {
            str2Percent: true
        })
        .saveAs(`Employee for Leave ${new Date().getUTCDate()}_${new Date().getMonth() + 1}_${new Date().getFullYear()}.xlsx`);
};