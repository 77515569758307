import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Timeline,
    Modal,
    Space
} from "antd";
import _ from 'lodash';


export default function ModalTimelineLeave(prop) {
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [editData, setEditData] = useState({
        "detail": {
            "Name[TH]": "-",
            "Leave Type": "-",
            "Start Date": "-",
            "End Date": "-",
            "Summary": "-",
            "Reason": "-",
        },
        "response": {
            "Request When": "-",
            "Request Status ": "-",
            "Response When": "-",
            "Response By ": "-",
            "Response Reason ": "-",
        }
    });
    const [color, setColor] = useState('');
    useEffect(() => {
        if (!_.isEmpty(prop.data)) {
            let status_name = '';
            let res_reason = '-';
            if (prop.data.status === 0) {
                status_name = 'ไม่อนุมัติ';
                setColor("red")
                res_reason = prop.data.responsreeason
            } else if (prop.data.status === 1) {
                status_name = 'เริ่มต้น'
            } else if (prop.data.status === 2) {
                status_name = 'รออนุมัติ'
                setColor("#2196F3")
            } else if (prop.data.status === 3) {
                status_name = 'อนุมัติ'
                setColor("green")
            }
            else if (prop.data.status === -1) {
                status_name = 'ยกเลิกโดยผู้ใช้งาน'
                setColor("red")
            } else if (prop.data.status === -2) {
                status_name = 'ยกเลิกโดยฝ่ายบุคคล';
                setColor("red")
                res_reason = prop.data.responsreeason
            }

            let tmp = {
                "detail": {
                    "Name[TH]": prop.data.name,
                    "Leave Type": prop.data.type,
                    "Start Date": prop.data.startDate,
                    "End Date": prop.data.endDate,
                    "Summary": prop.data.sumtotal,
                    "Reason": prop.data.reason,
                },
                "response": {
                    "Request When": prop.data.requestwhen,
                    "Request Status ": status_name,
                    "Response When": prop.data.responsewhen,
                    "Response By ": prop.data.approved_by,
                    "Response Reason ": res_reason,
                }
            }

            setEditData(tmp)
        }

        setModalEditOpen(prop.modalOpen);
    }, [prop]);

    return (
        <>
            <Modal
                title={`Leave Detail`}
                open={modalEditOpen}
                onCancel={() => {
                    setModalEditOpen(false);
                    prop.onClose();
                }}
                footer={null}
                closable={true}
                width={500}
            >
                <Timeline
                    style={{ margin: '20px' }}
                    items={[
                        {
                            color: '#2196F3',
                            children: (
                                <>
                                    <span style={{ color: 'rgba(33, 150, 243, 0.75)', marginBottom: '10px', fontWeight: 500 }}>Detail</span>
                                    {Object.keys(editData.detail).map(detail => (
                                        <Row>
                                            <Col span={10}>{detail}</Col>
                                            <Col span={14}>{editData.detail[detail]}</Col>
                                        </Row>
                                    ))}
                                </>
                            ),
                        },
                        {
                            color: color,
                            children: (
                                <>
                                    <span style={{ color: 'rgba(33, 150, 243, 0.75)', marginBottom: '10px', fontWeight: 500 }}>Response</span>
                                    {Object.keys(editData.response).map(response => (
                                        <Row>
                                            <Col span={10}>{response}</Col>
                                            <Col span={14}>{editData.response[response]}</Col>
                                        </Row>
                                    ))}
                                </>
                            ),
                        },
                    ]}
                />
            </Modal>
        </>
    );
}
