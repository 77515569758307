import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Button, message } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import anime from "animejs";
import _ from 'lodash'
import getAllHoliday from '../../../api/holiday/getAllHoliday'

import { useSelector, useDispatch } from 'react-redux'
import { getOverView, editdateOfweek } from "../../../slice/overviewSlice";

export default function Calender_week(props) {
    const dispatch = useDispatch()
    const animationRef = useRef(null);
    const dateToday = useSelector(getOverView);
    const [isLoading, setIsloading] = useState(true);
    const [objOne, setObjone] = useState({});
    const [holidayArray, setHolidayarray] = useState([]);
    const [weeknumber, setWeeknumber] = useState(dayjs().week());
    const [arrayDayofweek, setArraydayofweek] = useState([
        {
            day: 'Sun',
            number_of_days: '20',
            color_f: '#00000033',
            background: '#FFF',
        }
    ]);
    useEffect(() => {
        getAllHoliday((err, res) => {
            if (err) {
                console.log(err);
            }
            if (!err && res.statusCode === 200) {
                setHolidayarray(res.data.filter(h => h.enable));
                setUpDate(dateToday, res.data.filter(h => h.enable), dayjs().week());
                setTimeout(() => {
                    animationRef.current = anime({
                        targets: ".unitless-value",
                        translateX: [-120, 0],
                        duration: 300,
                        easing: 'easeInOutSine',
                        opacity: [0.5, 1],
                    });
                }, 100);
            } else {
                message.error(res?.message ?? 'fail')
            }
        });

        return () => {
            // cleanup
        };
    }, []);
    const setUpDate = async (activedate, holidayarray, Weeknumber) => {
        var tmp_data = [];
        var date = dayjs().week(Weeknumber).startOf('week');
        await Promise.all(new Array(7).fill(null).map((d, i) => {
            let color = '#00000033';
            let background = '#FFF';
            const is_holiday = holidayarray.filter(h => { return dayjs(h.date).format('YYYY-MM-DD') === date.format('YYYY-MM-DD') });
            if (!_.isEmpty(is_holiday)) {
                background = '#EF3C4040';
                color = '#FFFFFF'
            }
            if (!_.isEmpty(is_holiday) && activedate === date.format('YYYY-MM-DD')) {
                background = '#EF3C40BF';
                color = '#FFFFFF'
            } else if (activedate === date.format('YYYY-MM-DD')) {
                background = '#2196F3';
                color = '#FFFFFF'
            }
            tmp_data.push({
                date: date.format('YYYY-MM-DD'),
                day: date.format('ddd'),
                number_of_days: date.format('D'),
                color_f: color,
                background: background,
            })
            date = date.add(1, 'd')
        }));
        setArraydayofweek(tmp_data)
    }

    return (
        <div style={style.box}>
            <span style={{ color: 'var(--818283, #818283)' }}>
                Week Number {weeknumber}/{new Date().getFullYear()} | {dayjs().week(weeknumber).startOf('week').format('dddd, MMMM DD')} -  {dayjs().week(weeknumber).endOf('week').format('dddd, MMMM DD')}
            </span>

            <Row align={'middle'} justify='center' style={{ width: '100%' }}>
                <Col span={4}>
                    <Row justify={'start'}>
                        <Button type="primary" shape="circle" icon={<ArrowLeftOutlined />}
                            onClick={() => {
                                let t = weeknumber - 1
                                setWeeknumber(t)
                                animationRef.current = anime({
                                    targets: ".unitless-value",
                                    translateX: [120, 0],
                                    duration: 300,
                                    easing: 'easeOutExpo',
                                    opacity: [0.5, 0],
                                })
                                setUpDate(dateToday, holidayArray, t)
                                setTimeout(() => {
                                    animationRef.current = anime({
                                        targets: ".unitless-value",
                                        translateX: [120, 0],
                                        duration: 300,
                                        easing: 'easeInOutSine',
                                        opacity: [0, 1],
                                    })
                                }, 500);
                            }}
                        />
                    </Row>
                </Col>
                <Col span={16}>
                    <Row justify={'center'}>
                        <div style={style.row_day} className='unitless-value'>
                            {arrayDayofweek.map((_, i) => {
                                let ssf = Object.assign({ color: _.color_f, background: _.background }, style.mini_box_day)
                                return (
                                    <div style={style.box_day} onClick={() => {
                                        dispatch(editdateOfweek(_.date))
                                        setUpDate(_.date, holidayArray, weeknumber)
                                    }}>
                                        <span style={{ color: 'rgba(0, 0, 0, 0.50)' }}>{_.day}</span>
                                        <div style={ssf}>
                                            {_.number_of_days}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Row>
                </Col>
                <Col span={4}>
                    <Row justify={'end'}>
                        <Button type="primary" shape="circle" icon={<ArrowRightOutlined />}
                            onClick={() => {
                                let t = weeknumber + 1
                                setWeeknumber(t)
                                animationRef.current = anime({
                                    targets: ".unitless-value",
                                    translateX: [0, 120],
                                    duration: 300,
                                    easing: 'easeOutExpo',
                                    opacity: [0.5, 0],
                                })
                                setUpDate(dateToday, holidayArray, t)
                                setTimeout(() => {
                                    animationRef.current = anime({
                                        targets: ".unitless-value",
                                        translateX: [-120, 0],
                                        duration: 300,
                                        easing: 'easeInOutSine',
                                        opacity: [0, 1],
                                    })
                                }, 500);
                            }}
                        />
                    </Row>
                </Col>
            </Row>
        </div >
    )
}

const style = {
    box: {
        display: "flex",
        // width: '843px',
        // height: '380px',
        padding: "12px 0px",
        flexDirection: "column",
        // justifyContent: 'space-between',
        alignItems: "center",
        gap: "12px",
        flexShrink: 0,
        borderRadius: "16px",
        // background: '#76B9EA'
    },
    box_day: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
    },
    row_day: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "8px",
    },
    mini_box_day: {
        display: "flex",
        width: "50px",
        height: "50px",
        padding: "10px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        cursor: "pointer",

        fontSize: "22px",
        borderRadius: "8px",
    },
};
