import { configureStore } from '@reduxjs/toolkit'
import businessunitReducer from './slice/businessunitSlice'
import overviewReducer from './slice/overviewSlice'

export default configureStore({
  reducer: {
    businessUnit: businessunitReducer,
    overView: overviewReducer
  }
})
