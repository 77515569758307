import React, { useEffect, useState } from 'react'
import { Col, Row, Divider } from 'antd';
import _ from 'lodash';
import dayjs from 'dayjs';
import durationTime from '../../../utils/durations';
export default function DetailEmployee(props) {
    const [datas, setDatas] = useState([
        {
            'Phone Number': '-',
            'Employee Type': '-',
            'Group Position': '-'
        },
        {
            'Email': '-',
            'Position': '-',
            'Business Unit': '-'
        },
        {
            'Years Experience': '-',
            'Section': '-',
            'Tier Level': '-'
        },
        {
            'Entry Date': '-',
            'Department': '-',
        },
        {
            'Probation Date': '-',
        },
    ]);
    useEffect(() => {
        setUPdata(props.datas);
        return () => {
            // cleanup
        };
    }, [props]);
    const setUPdata = async (d) => {
        if (!_.isEmpty(d)) {
            var tmp_data = [...datas];
            console.log(d);
            await Object.keys(d).forEach(async (detail, key) => {
                if (detail === 'phone' && !_.isEmpty(d[detail])) {
                    if (d[detail] == '-') {
                        tmp_data[0]['Phone Number'] = d[detail];
                    } else {
                        tmp_data[0]['Phone Number'] = d[detail].substr(0, 3) + '-' + d[detail].substr(3, 3) + '-' + d[detail].substr(6, 4);
                    }
                }
                if (detail === 'employment_type' && !_.isEmpty(d[detail])) {
                    tmp_data[0]['Employee Type'] = d[detail]['name'];

                }
                if (detail === 'group_positions' && !_.isEmpty(d[detail])) {
                    tmp_data[0]['Group Position'] = d[detail]['name'];
                }
                if (detail === 'email' && !_.isEmpty(d[detail])) {
                    tmp_data[1]['Email'] = d[detail];
                }
                if (detail === 'positions' && !_.isEmpty(d[detail])) {
                    tmp_data[1]['Position'] = d[detail];
                }
                if (detail === 'bu' && !_.isEmpty(d[detail])) {
                    tmp_data[1]['Business Unit'] = d[detail];
                }
                if (detail === 'section' && !_.isEmpty(d[detail])) {
                    tmp_data[2]['Section'] = d[detail];
                }
                if (detail === 'tier_level' && !_.isEmpty(d[detail])) {
                    tmp_data[2]['Tier Level'] = d[detail];
                }
                if (detail === 'entry_date' && !_.isEmpty(d[detail])) {
                    const age_y = await durationTime(dayjs(d[detail]).format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD'),)
                    tmp_data[2]['Years Experience'] = `${age_y.years} years ${age_y.months} months ${age_y.days} days`
                    tmp_data[3]['Entry Date'] = dayjs(d[detail]).format('DD/MM/YYYY');

                    if (!_.isEmpty(d['probation_date'])) {
                        tmp_data[4]['Probation Date'] = dayjs(d['probation_date']).format('DD/MM/YYYY');
                    } else {
                        tmp_data[4]['Probation Date'] = dayjs(d[detail]).add(119, 'day').format('DD/MM/YYYY');// cal entry_date +119
                    }
                }
                if (detail === 'department' && !_.isEmpty(d[detail])) {
                    tmp_data[3]['Department'] = d[detail];
                }
            });
            setDatas(tmp_data);
        }
    }
    return (
        <div style={styles.box}>
            <Divider orientation="left" style={{ margin: 0 }}>
                Employee Detail
            </Divider>
            {datas.map((d, key) => {
                return (
                    <Row>
                        {
                            Object.keys(d).map((sup_d) => {
                                return (
                                    <Col span={8}>
                                        <div>
                                            <span style={{ textTransform: 'capitalize', color: 'rgba(33, 150, 243, 0.75)', fontStyle: 'normal', fontWeight: 600 }}>{sup_d} : </span> {d[sup_d]}
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                )
            })}
        </div >
    )
}
const styles = {
    box: {
        borderRadius: '1rem',
        border: '1px solid rgba(129, 130, 131, 0.10)',
        background: '#FFF',
        boxShadow: '1px 3px 4px 0px rgba(129, 130, 131, 0.15)',
        padding: '20px', margin: '20px 15px 15px 0',
        display: 'flex',
        padding: '1.5rem 1.5rem 1rem 1rem',
        flexDirection: 'column',
        alignItems: 'flexStart',
        gap: '2.5rem',
        overflow: 'hidden',
    }
}