import { Excel } from "antd-table-saveas-excel";
import _ from 'lodash'
import dayjs from 'dayjs';
export default async function handlePrint(data) {
    const excel = new Excel();
    var calendar_data = [];
    var datas = [];
    await Promise.all(
        data.map(async (d, index) => {
            let tmp = {
                key: index + 1,
                employeeId: d.employeeId,
                name: d.firstname_th + ' ' + d.lastname_th,
            }
            await Promise.all(
                d.calendar.map((calendar) => {
                    tmp["checkin_" + calendar.days.replaceAll('-', '_')] = _.isEmpty(calendar.work_time.in) ? '-' : dayjs(calendar.work_time.in.createdAt).format('HH:mm')
                    tmp["manually_" + calendar.days.replaceAll('-', '_')] = _.isEmpty(calendar.work_time.in) ? false : calendar.work_time.manually
                    tmp["checkout_" + calendar.days.replaceAll('-', '_')] = _.isEmpty(calendar.work_time.out) ? '-' : dayjs(calendar.work_time.out.createdAt).format('HH:mm')
                    tmp["sumtotal_" + calendar.days.replaceAll('-', '_')] = calendar.work_time.sumtotal
                    tmp["earlyin_" + calendar.days.replaceAll('-', '_')] = calendar.work_time.early_in
                    tmp["lateout_" + calendar.days.replaceAll('-', '_')] = _.isEmpty(calendar.leaves) ? '-' : calendar.leaves.type.name
                    tmp["otsum_" + calendar.days.replaceAll('-', '_')] = _.isEmpty(calendar.over_time) ? '-' : calendar.over_time.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.total), 0);
                    if (calendar_data.length < 8) {
                        calendar_data.push({
                            title: calendar.days.replaceAll('-', '_').replaceAll('-', '_'),
                            align: "center",
                            children: [
                                {
                                    title: 'Check in',
                                    dataIndex: 'checkin_' + calendar.days.replaceAll('-', '_'),
                                    key: 'checkin_' + calendar.days.replaceAll('-', '_'),
                                    width: 150,
                                    render(_, record) {
                                        return {
                                            props: {
                                                style: { background: record['manually_'+calendar.days.replaceAll('-', '_')] ? "rgba(33, 150, 243, 0.2)" : "" }
                                            },
                                            children: <div>{_}</div>
                                        };
                                    }
                                },
                                {
                                    title: 'Check out',
                                    dataIndex: 'checkout_' + calendar.days.replaceAll('-', '_'),
                                    key: 'checkout_' + calendar.days.replaceAll('-', '_'),
                                    width: 150,
                                },
                                {
                                    title: 'Sum',
                                    dataIndex: 'sumtotal_' + calendar.days.replaceAll('-', '_'),
                                    key: 'sumtotal_' + calendar.days.replaceAll('-', '_'),
                                    width: 150,
                                },
                                {
                                    title: 'Leave',
                                    dataIndex: 'leave_' + calendar.days.replaceAll('-', '_'),
                                    key: 'laeve_' + calendar.days.replaceAll('-', '_'),
                                    width: 150,
                                },
                                {
                                    title: 'Early In',
                                    dataIndex: 'earlyin_' + calendar.days.replaceAll('-', '_'),
                                    key: 'earlyin_' + calendar.days.replaceAll('-', '_'),
                                    width: 150,
                                },
                                {
                                    title: 'Late out',
                                    dataIndex: 'lateout_' + calendar.days.replaceAll('-', '_'),
                                    key: 'lateout_' + calendar.days.replaceAll('-', '_'),
                                    width: 150,
                                },
                                {
                                    title: 'OT Sum',
                                    dataIndex: 'otsum_' + calendar.days.replaceAll('-', '_'),
                                    key: 'otsum_' + calendar.days.replaceAll('-', '_'),
                                    width: 150,
                                },
                            ],
                        },)
                    }
                })
            );
            datas.push(tmp)
        })
    );
    const columnsTable = [
        {
            title: "No.",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Employee id",
            dataIndex: "employeeId",
            key: "employeeId"
        },
        {
            title: "Firstname - Lastname",
            dataIndex: "name",
            key: "name",
        },
    ];
    excel
        .addSheet(`Weekreport ${new Date().getUTCDate()}_${new Date().getMonth() + 1}_${new Date().getFullYear()}`)
        .addColumns(columnsTable.concat(calendar_data))
        .addDataSource(datas.sort((a, b) => a.employeeId - b.employeeId), {
            str2Percent: true
        })
        .saveAs(`Weekreport ${new Date().getUTCDate()}_${new Date().getMonth() + 1}_${new Date().getFullYear()}.xlsx`);
};