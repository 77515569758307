import React, { useEffect } from 'react'
import { Space, Col, Row, Form, Input, message, notification, Button, Switch, DatePicker } from 'antd';
import addHoliday from '../../api/holiday/addHoliday'
export default function AddFormHoliday(props) {
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };
    const [form] = Form.useForm();
    const formStyle = {
        maxWidth: 'none',
        padding: 24,
    };

    useEffect(() => {
        // effect
        form.setFieldsValue({ enable: true });
        return () => {
            // cleanup
        };
    }, []);
    return (
        <div style={{ padding: '20px', margin: '20px 15px 15px 20px', backgroundColor: "#FFF" }}>
            {contextHolder}
            <Row>
                <Col span={4}></Col>
                <Col span={16}>
                    <Form form={form} name="advanced_search" style={formStyle} onFinish={(e) => {
                        addHoliday(e, (err, res) => {
                            if (err) {
                                openNotificationWithIcon('error', 400, err ?? 'error not known');
                            }
                            if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                                form.resetFields();
                                props.handleUpdated();
                                props.onClose();
                                message.success("This is a success add Holiday");
                            }
                            if (res.statusCode >= 400) {
                                openNotificationWithIcon('error', res.statusCode, res.message);
                            }
                        })
                    }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', flexWrap: 'wrap' }}>
                            <Row>
                                <Space>
                                    <Col>
                                        <Form.Item
                                            name={`name`}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Input something!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Holiday Entitlement" showCount maxLength={255} />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            name={`date`}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Input something!',
                                                },
                                            ]}
                                        >
                                            <DatePicker />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            label="Status"
                                            name={`enable`}
                                            valuePropName="checked"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Input something!',
                                                },
                                            ]}
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                </Space>
                            </Row>
                            <Row justify={'center'}>
                                <Col>
                                    <Button type="primary" htmlType="submit">
                                        Add Holiday Entitlement
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Col>
                <Col span={4}></Col>
            </Row>
        </div >
    )
}