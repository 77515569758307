import React, { useEffect, useState } from 'react'
import { Table } from 'antd';
import moment from 'moment';
export default function DetailTableemployee(props) {
    const [datas, setDatas] = useState([]);
    const [isLoading, setIsloading] = useState(!props.isFetch);
    const columns = [
        {
            title: 'Date',
            dataIndex: 'day',
            key: 'day',
            render: (_, record) => {
                return <div>{moment(_).format('DD/MM/YYYY')}</div>
            },
            sorter: (a, b) => new Date(a.day) - new Date(b.day),
            align: 'center',
        },
        {
            title: 'Day',
            dataIndex: 'dayname',
            key: 'dayname',
            width: 150,
            align: 'center',
        },
        {
            title: 'Check In',
            dataIndex: 'worktime_in',
            key: 'worktime_in',
            width: 150,
            render(_, record) {
                return {
                    props: {
                        style: { background: record.manually ? "rgba(33, 150, 243, 0.2)" : "" }
                    },
                    children: <div>{_}</div>
                };
            }
        },
        {
            title: 'Location',
            dataIndex: 'worktime_in_localtion',
            key: 'worktime_in_localtion',
            width: 150,
        },
        {
            title: 'Check Out',
            dataIndex: 'worktime_out',
            key: 'worktime_out',
            width: 150,
        },
        {
            title: 'Location',
            dataIndex: 'worktime_out_localtion',
            key: 'worktime_out_localtion',
            width: 150,
        },
        {
            title: 'Summary',
            dataIndex: 'worktime_total',
            key: 'worktime_total',
            width: 150,
            render: (_, record) => {
                let resplit = _.split('.');
                var tmp = '-';
                if (resplit.length > 1) {
                    tmp = resplit[0] + ' hr. ' + resplit[1] + ' min'
                }
                return tmp
            },
        },
        {
            title: 'OT Summary',
            dataIndex: 'overtime_total',
            key: 'overtime_total',
            align: 'center',
            render: (_, record) => {
                if (String(_).indexOf('.') === -1) {
                    if (String(_) === '-') {
                        return _
                    }
                    return _ + ' hr. ' + 0 + ' min'
                } else {
                    return Math.floor(_) + ' hr. ' + 30 + ' min'
                }
            },
        },
        {
            title: 'Leave',
            dataIndex: 'leave_type',
            key: 'leave_type',
        },
        {
            title: 'Duration',
            dataIndex: 'leave_duration',
            key: 'leave_duration',
        },
    ];
    useEffect(() => {
        setDatas(props.data);
        setIsloading(!props.isFetch);
        return () => {
            // cleanup
        };
    }, [props]);

    return (
        <Table
            columns={columns}
            scroll={{
                x: 2000,
                y: 2500,
            }}
            loading={isLoading}
            dataSource={datas}
        />
    )
}
