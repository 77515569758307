import React, { useEffect, useState } from 'react'
import { Col, Row, Divider, } from 'antd';
import _ from 'lodash';
import dayjs from 'dayjs';
import durationTime from '../../../utils/durations';
export default function ProfileEmployee(props) {
    const [datas, setDatas] = useState([
        {
            'gender': '-',
            'age': '-',
        },
        {
            religion: '-',
            birthday: '-',
        },
        {
            nationality: '-',
            home_address: '-',
        },
        {
            identificationcard: '-',
            mailing_address: '-',
        },
        {
            issuedate: '-',
        },
        {
            expireddate: '-',
        },
    ]);
    useEffect(() => {
        setUPdata(props.datas);
        return () => {
            // cleanup
        };
    }, [props]);
    const setUPdata = async (d) => {
        if (!_.isEmpty(d)) {
            var tmp_data = [...datas];
            await Object.keys(d).forEach(async (detail, key) => {
                if (detail === 'address' && !_.isEmpty(d[detail])) {
                    tmp_data[2]['home_address'] = d[detail]['address_detail'] + ' ' + d[detail]['road'] + d[detail]['soi'] + ' ' + d[detail]['subdistrict'] + ' ' + d[detail]['district'] + ' ' + d[detail]['province'] + ' ' + d[detail]['postal_code']
                }
                if (detail === 'personal' && !_.isEmpty(d[detail])) {
                    tmp_data[1]['religion'] = d[detail]['religion']
                    tmp_data[2]['nationality'] = d[detail]['nationality']
                    tmp_data[3]['identificationcard'] = d[detail]['number']
                    tmp_data[3]['mailing_address'] = d[detail]['address_detail'] + ' ' + d[detail]['road'] + d[detail]['soi'] + ' ' + d[detail]['subdistrict'] + ' ' + d[detail]['district'] + ' ' + d[detail]['province'] + ' ' + d[detail]['postal_code']
                    tmp_data[4]['issuedate'] = d[detail]['issue_card'] ? dayjs(d[detail]['issue_card']).format('DD/MM/YYYY') : '-';
                    tmp_data[5]['expireddate'] = d[detail]['expire_date'] ? dayjs(d[detail]['expire_date']).format('DD/MM/YYYY') : '-';
                }
                if (detail === 'birthday_date' && !_.isEmpty(d[detail])) {
                    const age_y = await durationTime(dayjs(d[detail]).format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD'),)
                    tmp_data[0]['age'] = `${age_y.years} years ${age_y.months} months ${age_y.days} days`

                    tmp_data[1]['birthday'] = d[detail] ? dayjs(d[detail]).format('DD/MM/YYYY') : '-';
                }
                if (detail === 'gender' && !_.isEmpty(d[detail])) {
                    tmp_data[0]['gender'] = d[detail]['name']
                }
            });
            setDatas(tmp_data);
        }
    }
    return (
        <div style={styles.box}>
            <Divider orientation="left" style={{ margin: 0 }}>
                Employee Profile
            </Divider>
            {datas.map((d, key) => {
                return (
                    <Row>
                        {
                            Object.keys(d).map((sup_d) => {
                                if (sup_d === 'home_address' || sup_d === 'mailing_address') {
                                    return (
                                        <Col span={12}>
                                            <div>
                                                <span style={{ textTransform: 'capitalize', color: 'rgba(33, 150, 243, 0.75)', fontStyle: 'normal', fontWeight: 600 }}>{sup_d} : </span>
                                                <div>{d[sup_d]}</div>
                                            </div>
                                        </Col>
                                    )
                                } else {
                                    return (
                                        <Col span={12}>
                                            <div>
                                                <span style={{ textTransform: 'capitalize', color: 'rgba(33, 150, 243, 0.75)', fontStyle: 'normal', fontWeight: 600 }}>{sup_d} : </span>{d[sup_d]}
                                            </div>
                                        </Col>
                                    )
                                }
                            })
                        }
                    </Row>
                )
            })}
        </div >
    )
}
const styles = {
    box: {
        borderRadius: '1rem',
        border: '1px solid rgba(129, 130, 131, 0.10)',
        background: '#FFF',
        boxShadow: '1px 3px 4px 0px rgba(129, 130, 131, 0.15)',
        padding: '20px',
        display: 'flex',
        padding: '1.5rem 1.5rem 1rem 1rem',
        flexDirection: 'column',
        alignItems: 'flexStart',
        gap: '2.5rem',
        height: '100%',
    }
}