
import React, { useState, useEffect, } from 'react'
export default function CalendarBlank(props) {
    const [activedateMenu, setActivedateMenu] = useState(props.activedateMenu)
    useEffect(() => {
        setActivedateMenu(props.activedateMenu)
    }, [props])
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="CalendarBlank">
                <path id="Vector" d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z" stroke={activedateMenu === 'worktime' ? "#2196F3" : "#818283"}  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path id="Vector_2" d="M16.5 2.25V5.25" stroke={activedateMenu === 'worktime' ? "#2196F3" : "#818283"}  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path id="Vector_3" d="M7.5 2.25V5.25" stroke={activedateMenu === 'worktime' ? "#2196F3" : "#818283"}  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path id="Vector_4" d="M3.75 8.25H20.25" stroke={activedateMenu === 'worktime' ? "#2196F3" : "#818283"}  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
        </svg>
    )
}
