import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Input, message, Radio, DatePicker, Space } from 'antd'
import { SearchOutlined, UploadOutlined } from '@ant-design/icons'
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import getAllWorktime from '../../api/worktime/getAll';
import TableWorktime from '../../components/worktime/tableWorktime'

export default function Worktimes(props) {
    const [isFetch, setIsfetch] = useState(false);
    const [isLoading, setIsloading] = useState(true);
    const [startDate, setStartdate] = useState(dayjs())
    const [endDate, setEnddate] = useState(dayjs());
    const [datas, setDatas] = useState([]);
    const [datasFilter, setDatasFilter] = useState([]);
    useEffect(() => {
        if (!isFetch) {
            fetch_worktime(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
        }

        return () => {
            // cleanup
        };
    }, []);
    const fetch_worktime = (startDate, endDate) => {
        getAllWorktime(startDate, endDate, (err, res) => {
            if (err) {
                console.log(err);
            }
            if (!err && res.statusCode === 200) {
                let tmp = res.data.filter(worktime => worktime.type_event === 'work_time').sort((a, b) => a.employeeId - b.employeeId).map((worktime, index) => { return { ...worktime, key: index } })
                setDatas([...tmp]);
                setDatasFilter([...tmp]);
                setTimeout(() => {
                    setIsfetch(true);
                    setIsloading(false);
                }, 1000);

                // message.success('success')
            } else {
                message.error(res?.message ?? 'fail')
            }
        })
    }
    const headers = [
        { label: "No.", key: "key" },
        { label: "Employee ID", key: "employeeId" },
        { label: "Name", key: "name" },
        { label: "Start Date", key: "startDate" },
        { label: "Check In", key: "starttime" },
        { label: "Location", key: "location_start" },
        { label: "End Date", key: "endDate" },
        { label: "Check Out", key: "endtime" },
        { label: "Location", key: "location_end" },
        { label: "Summary ", key: "sumtotal" },
        { label: "Business Unit", key: "business_groups" }
    ];
    return (
        <div style={{ padding: '20px', margin: '20px 15px 15px 20px', background: '#FFF', borderRadius: '16px' }}>
            <Row align='middle' justify='space-between' style={{ marginBottom: '5px' }}>
                <Col span={10} >
                    <Row style={{ color: "var(--ant-primary-color)", marginBottom: '10px' }}>
                        {/* <Radio.Group
                            style={{
                                marginBottom: 8,
                            }}
                        >
                            <Radio.Button value="top">Today</Radio.Button>
                            <Radio.Button value="left">Day Range</Radio.Button>
                        </Radio.Group> */}
                    </Row>
                </Col>
                <Col span={14}>
                    <Row style={{ flexWrap: 'nowrap', justifyContent: 'flex-end', gap: '8px' }}>
                        <DatePicker onChange={(e) => { setStartdate(e) }} defaultValue={startDate} />
                        <DatePicker onChange={(e) => { setEnddate(e) }} defaultValue={endDate} />
                        <Button
                            loading={!isFetch}
                            type={'primary'}
                            style={{
                                borderRadius: '8px',
                                fontWeight: '500',
                                boxShadow: "unset",
                                textShadow: "unset",
                            }}
                            onClick={() => { fetch_worktime(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')) }}
                        >
                            Submit
                        </Button>
                    </Row>
                </Col>
            </Row>
            <Row justify={'end'} style={{ marginBottom: '10px' }}>
                <Col ></Col>
                <Col >
                    <Space>
                        <Input
                            suffix={
                                <SearchOutlined style={{ color: 'rgba(79, 79, 79, 0.5)' }} />
                            }
                            className='ant-inputsearch search-border'
                            placeholder={`Search....`}
                            style={{
                                margin: '0px 0px 0px 10px',
                                borderRadius: '8px',
                                maxWidth: '200px'
                            }}
                            onChange={e => {
                                const currValue = e.target.value
                                const filteredData = datasFilter.filter(entry =>
                                    Object.keys(entry).filter((k) => k).some(s => {
                                        return String(entry[s])
                                            .toLowerCase()
                                            .includes(currValue.toLowerCase())
                                    })
                                )
                                setDatas(filteredData)
                            }}
                        />
                        <Button
                            loading={!isFetch}
                            style={{
                                borderRadius: '8px',
                                color: 'var(--ant-primary-color)',
                                border: '1px solid var(--ant-primary-color)',
                                fontWeight: '500',
                                boxShadow: "unset",
                                textShadow: "unset",
                            }}
                        >
                            <CSVLink data={datas} filename={`worktime_${dayjs().format('YYYY-MM-DD')}.csv`} headers={headers}>
                                <UploadOutlined /> Export
                            </CSVLink>
                        </Button>
                    </Space>
                </Col>
            </Row>
            <TableWorktime data={datas} isLoading={isLoading}
                handleUpdated={() => {
                    setIsloading(true);
                    setTimeout(() => {
                        fetch_worktime(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
                    }, 1000);
                }}
            />
        </div>
    )
}
