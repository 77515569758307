import { Excel } from "antd-table-saveas-excel";

export default function handlePrint (dataTable)  {
    const excel = new Excel();
    dataTable = dataTable.map(dt => {
        return {
            employeeId: dt.employeeId,
            title: dt.title.name ?? "-",
            firstname_th: dt.firstname_th,
            lastname_th: dt.lastname_th,
            personal_id: dt.personal?.number ?? '-',
            house_no: dt.address?.address_detail ?? '-',
            soi: dt.address?.soi ?? '-',
            road: dt.address?.road ?? '-',
            subdistrict: dt.address?.subdistrict ?? '-',
            district: dt.address?.district ?? '-',
            province: dt.address?.province ?? '-',
            postal_code: dt.address?.postal_code ?? '-'
        }
    });
    const columnsTable = [
        {
            title: "Employee id",
            dataIndex: "employeeId",
            key: "employeeId"
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title"
        },
        {
            title: "First Name",
            dataIndex: "firstname_th",
            key: "firstname_th"
        },
        {
            title: "Last Name",
            dataIndex: "lastname_th",
            key: "lastname_th"
        },
        {
            title: "Personal id",
            dataIndex: "personal_id",
            key: "personal_id"
        },
        {
            title: "House no.",
            dataIndex: "house_no",
            key: "house_no"
        },
        {
            title: "Soi",
            dataIndex: "soi",
            key: "soi"
        },
        {
            title: "Road",
            dataIndex: "road",
            key: "road"
        },
        {
            title: "Sub-district",
            dataIndex: "subdistrict",
            key: "subdistrict"
        },
        {
            title: "District",
            dataIndex: "district",
            key: "district"
        },
        {
            title: "Province",
            dataIndex: "province",
            key: "province"
        },
        {
            title: "Postal Code",
            dataIndex: "postal_code",
            key: "postal_code"
        },
    ];
    excel
        .addSheet("sheet 1")
        .addColumns(columnsTable)
        .addDataSource(dataTable, {
            str2Percent: true
        })
        .saveAs(`Employee for Tax ${new Date().getUTCDate()}_${new Date().getMonth() + 1}_${new Date().getFullYear()}.xlsx`);
};