import React, { useState, useEffect, } from 'react'
import {
    Layout,
    Menu,
    Skeleton,
    Spin,
    Button,
    message,
    Avatar,
    Dropdown,
} from 'antd'
import {
    MenuFoldOutlined, MenuUnfoldOutlined,
} from '@ant-design/icons'
import _ from 'lodash'
import { useNavigate, Outlet, useLocation } from 'react-router-dom'
import getAllBU from '../api/business_unit/getAllBusiness_unit'
import getAllDepartment from '../api/department/getAllDepartment'
import getAllSection from '../api/section/getAll'
import getAllTitle from '../api/title/getAll'
import getAllGroupPosition from '../api/groupposition/getAllGroupPosition'
import getAllPosition from '../api/positions/getAll'
import getAllEmploymentType from '../api/employment_type/getAll'
import Component_changePassword from '../components/main/changePassword'
import Icon_browser from '../asset/icon/browser'
import Icon_NavigationArrow from '../asset/icon/NavigationArrow'
import Icon_Users from '../asset/icon/Users'
import Icon_CalendarBlank from '../asset/icon/CalendarBlank'
import Icon_FileText from '../asset/icon/FileText'
import Icon_Clock from '../asset/icon/Clock'
import Icon_Gear from '../asset/icon/Gear'
import Icon_Wrench from '../asset/icon/Wrench'


import { useDispatch, } from 'react-redux'
import { editdataBU, editdataDepartment, editdataSection, editdataTitle, editdataEmployee_type, editdataGroup_position, editdataPosition } from '../slice/businessunitSlice'

const { Header, Content, Sider, } = Layout
function getItem(label, key, icon, children, type, onClick) {
    return {
        key,
        icon,
        children,
        label,
        type,
        onClick
    }
}

export default function LayoutMain(props) {
    const navigate = useNavigate()
    const [Isfetch, setIsfetch] = useState(false)
    const [Issider, setIssider] = useState(true)
    const [modalResetOpen, setModalResetOpen] = useState(false);
    const location = useLocation();
    const [isUser, setIsuser] = useState({})
    const [activedateMenu, setActivedateMenu] = useState(location.pathname.split('/')[1].replace('%20', ' '))
    const items = [
        getItem('Overview', 'sub1', <><Icon_browser activedateMenu={activedateMenu} />&nbsp;</>, [
            getItem(
                'Overview',
                'overview',
                <> <Icon_NavigationArrow activedateMenu={activedateMenu} /> &nbsp;</>
                ,
                null,
                null,
                () => { setActivedateMenu('overview'); navigate('/overview') }
            ),
            getItem(
                'Employee',
                'employee',
                <><Icon_Users activedateMenu={activedateMenu} /> &nbsp;</>,
                null,
                null,
                () => { setActivedateMenu('employee'); navigate('/employee') }
            ),
            getItem(
                'Work Time',
                'work Time',
                <><Icon_CalendarBlank activedateMenu={activedateMenu} />&nbsp;</>,
                null,
                null,
                () => { setActivedateMenu('work Time'); navigate('/work Time') }
            ),
            getItem(
                'Leave',
                'leave',
                <><Icon_FileText activedateMenu={activedateMenu} />&nbsp;</>,
                null,
                null,
                () => { setActivedateMenu('leave'); navigate('/leave') }
            ),
            getItem(
                'Overtime',
                'overtime',
                <><Icon_Clock activedateMenu={activedateMenu} /> &nbsp;</>,
                null,
                null,
                () => { setActivedateMenu('overtime'); navigate('/overtime') }
            )
        ]),
        getItem('Setting', 'sub2', <> <Icon_Gear activedateMenu={activedateMenu} /> &nbsp;</>, [
            getItem(
                'Holiday Entitlement',
                'holiday Entitlement',
                null,
                null,
                null,
                () => { setActivedateMenu('holiday Entitlement'); navigate('/holiday Entitlement') }
            ),
            getItem(
                'Group Permission',
                'group Permission',
                null,
                null,
                null,
                () => { setActivedateMenu('group Permission'); navigate('/group Permission') }
            ),
            getItem(
                'Broad Cast',
                'broad cast',
                null,
                null,
                null,
                () => { setActivedateMenu('broadcast'); navigate('/broad cast') }
            )
        ]),
        getItem('Config', 'sub3', <><Icon_Wrench activedateMenu={activedateMenu} />&nbsp;</>, [
            getItem(
                'Title Name',
                'title Name',
                null,
                null,
                null,
                () => { setActivedateMenu('title Name'); navigate('/title Name') }
            ),
            getItem(
                'Education Level',
                'education Level',
                null,
                null,
                null,
                () => { setActivedateMenu('education Level'); navigate('/education Level') }
            ),
            getItem(
                'Positions',
                'positions',
                null,
                null,
                null,
                () => { setActivedateMenu('positions'); navigate('/positions') }
            ),
            getItem(
                'Sections',
                'sections',
                null,
                null,
                null,
                () => { setActivedateMenu('sections'); navigate('/sections') }
            ),
            getItem(
                'Departments',
                'departments',
                null,
                null,
                null,
                () => { setActivedateMenu('departments'); navigate('/departments') }
            ),
            getItem(
                'Business Unit',
                'business Unit',
                null,
                null,
                null,
                () => { setActivedateMenu('business Unit'); navigate('/business Unit') }
            ),
            getItem(
                'Group Positions',
                'group Positions',
                null,
                null,
                null,
                () => { setActivedateMenu('group Positions'); navigate('/group Positions') }
            ),
        ]),
    ];
    const dispatch = useDispatch()
    useEffect(() => {
        if (!Isfetch && props.token) {
            let user = sessionStorage.getItem('token');
            user = JSON.parse(user);
            setIsuser(user);
            getAllBU((err, res) => {
                if (err) {
                    console.log(err);
                }
                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                    dispatch(editdataBU(res.data))
                } else {
                    message.error(res?.message ?? 'fail')
                }
            })
            getAllDepartment((err, res) => {
                if (err) {
                    console.log(err);
                }
                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                    dispatch(editdataDepartment(res.data))
                } else {
                    message.error(res?.message ?? 'fail')
                }
            })
            getAllSection((err, res) => {
                if (err) {
                    console.log(err);
                }
                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                    dispatch(editdataSection(res.data))
                } else {
                    message.error(res?.message ?? 'fail')
                }
            })
            getAllTitle((err, res) => {
                if (err) {
                    console.log(err);
                }
                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                    dispatch(editdataTitle(res.data))
                } else {
                    message.error(res?.message ?? 'fail')
                }
            });
            getAllGroupPosition((err, res) => {
                if (err) {
                    console.log(err);
                }
                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                    dispatch(editdataGroup_position(res.data))
                } else {
                    message.error(res?.message ?? 'fail')
                }
            });
            getAllPosition((err, res) => {
                if (err) {
                    console.log(err);
                }
                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                    dispatch(editdataPosition(res.data))
                } else {
                    message.error(res?.message ?? 'fail')
                }
            });
            getAllEmploymentType((err, res) => {
                if (err) {
                    console.log(err);
                }
                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                    dispatch(editdataEmployee_type(res.data))
                } else {
                    message.error(res?.message ?? 'fail')
                }
            });
            setIsfetch(true);
        }
    }, []);

    return (
        <div className='header'>

            <Spin spinning={!Isfetch} >
                <Layout>
                    {Issider && (
                        <Sider >
                            <Menu
                                style={{
                                    // width: 256,
                                    height: '100%'
                                }}
                                defaultSelectedKeys={[location.pathname.split('/')[1].replace('%20', ' ')]}
                                defaultOpenKeys={['sub1',]}
                                mode='inline'
                                items={items}
                            />
                        </Sider>
                    )}
                    <Layout >
                        <Header
                            style={{
                                background: '#ffffff',
                                padding: '10px',
                                // height: '50px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                                // borderBottom: '1px solid #E0E0E0'
                            }}
                        >
                            <div style={{
                                color: '#2196F3', textTransform: 'capitalize', fontSize: '2rem',
                                display: 'flex',
                                flexWrap: 'nowrap',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '0.5rem'
                            }} >
                                <Button icon={Issider ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />} onClick={() => { setIssider(!Issider) }} />
                                {activedateMenu}
                            </div>
                            <div style={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>

                                {_.isEmpty(isUser) ? (
                                    <Skeleton.Avatar active style={{
                                        display: 'flex',
                                        flexWrap: 'nowrap',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }} />
                                ) : (
                                    <Dropdown
                                        menu={{
                                            items: [
                                                getItem(
                                                    'Reset password',
                                                    'resetpassword',
                                                    null,
                                                    null,
                                                    null,
                                                    () => { setModalResetOpen(!modalResetOpen); }
                                                ),
                                                getItem(
                                                    'Log out',
                                                    'logout',
                                                    null,
                                                    null,
                                                    null,
                                                    () => { sessionStorage.removeItem('token'); navigate('/login') }
                                                ),
                                            ]
                                        }}
                                        arrow={{
                                            pointAtCenter: true,
                                        }}
                                    >
                                        <Avatar style={{ cursor: 'pointer' }}>{isUser.username}</Avatar>
                                    </Dropdown>
                                )}
                            </div>
                        </Header>
                        <Component_changePassword
                            modalOpen={modalResetOpen}
                            onClose={() => {
                                setModalResetOpen(false);
                            }} />
                        <Content >
                            <Outlet />
                        </Content>
                    </Layout>
                </Layout>
            </Spin>
        </div>
    )
}
