import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Modal,
  message,
  notification,
  Switch, DatePicker, Space
} from "antd";
import dayjs from 'dayjs';

import editHoliday from "../../api/holiday/editHoliday";



export default function ModaleditHoliday(prop) {
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [name, setName] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  useEffect(() => {
    setEditData(prop.data);
    setName(prop.data.name);
    setModalEditOpen(prop.modalOpen);
    form.setFieldsValue({ name: prop.data.name, date: dayjs(prop.data.date), enable: prop.data.enable });
  }, [prop]);
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };
  return (
    <>
      <Modal
        title={<div style={{ color: '#2196F3' }}>{`Edit ${name}`}</div>}
        open={modalEditOpen}
        onCancel={() => {
          setModalEditOpen(false);
          form.resetFields();

          prop.onClose();
        }}
        footer={null}
        closable={true}
        width={500}
      >
        {contextHolder}
        <Form
          autoComplete="off"
          layout="horizontal"
          size="large"
          form={form}
          style={{ padding: '10px' }}
          onFinish={(e) => {
            editHoliday(
              editData.id,
              {
                name: e.name,
                date: dayjs(e.date).format("YYYY-MM-DD"),
                enable: e.enable,
              },
              (err, res) => {
                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                  prop.handleUpdated();
                  setModalEditOpen(false);
                  prop.onClose();
                  message.success("Edit success");
                  form.resetFields();
                } else {
                  openNotificationWithIcon('error', 400, err ?? 'error not known');
                }
              }
            );
          }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name={`name`}
                rules={[
                  {
                    required: true,
                    message: 'Input something!',
                  },
                ]}
              >
                <Input placeholder="name" showCount maxLength={255} />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ alignItems: "center" }}>
            <Space>
              <Col>
                <Form.Item
                  name={`date`}
                  rules={[
                    {
                      required: true,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Status"
                  name={`enable`}
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                      message: 'Input something!',
                    },
                  ]}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Space>


          </Row>
          <Row justify="center" style={{ padding: "0 25.57px", gap: "8px" }}>
            <Button
              htmlType="button"
              style={{
                boxShadow: "unset",
                textShadow: "unset",
                borderRadius: "8px",
                fontSize: "14px",
                fontWeight: "500",
              }}
              onClick={() => {
                form.resetFields();
                prop.onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              style={{
                boxShadow: "unset",
                textShadow: "unset",
                borderRadius: "8px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Edit Holiday
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
