import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Modal,
    Tag
} from "antd";
import moment from 'moment';



export default function ModaleditBroadcast(prop) {
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [editData, setEditData] = useState({ Messages: [] });
    const [name, setName] = useState('');
    useEffect(() => {
        setEditData(prop.data);
        setName(prop.data.exmple)
        setModalEditOpen(prop.modalOpen);

    }, [prop]);

    return (
        <>
            <Modal
                title={<div style={{ color: '#2196F3' }}>{`View ${name}`}</div>}
                open={modalEditOpen}
                onCancel={() => {
                    setModalEditOpen(false);
                    prop.onClose();
                }}
                footer={null}
                closable={true}
                width={1000}
            >
                <Row justify={'center'} style={{ gap: '8px' }}>
                    <Col>
                        <spam>Date Broadcast :</spam> {moment(editData.dateBroadcast).format('DD/MM/YYYY HH:mm')}
                    </Col>
                    <Col >
                        <spam>Status : &nbsp;</spam>
                        {editData.status === 1 && (<Tag color="#91F363">Ready to send</Tag>)}
                        {editData.status === 2 && (<Tag color="#D1D1D1">Draft</Tag>)}
                        {editData.status === 3 && (<Tag color="#6393F3">Sent</Tag>)}
                        {editData.status === 4 && (<Tag color="#f50">Fail</Tag>)}
                    </Col>
                </Row>
                <Row justify={'center'}>

                </Row>
                <Row justify={'center'}>
                    <Col>
                        <spam>Messages : &nbsp;</spam>
                        {editData.Messages.reduce((accumulator, currentValue, index) => {
                            if (index !== 0) {
                                return accumulator + ' ,' + currentValue.detail
                            } else {
                                return currentValue.detail
                            }

                        }, '')}
                    </Col>
                </Row>
            </Modal>
        </>
    );
}
