import React, { useEffect, useState } from "react";
import {
    Button,
    Row,
    Col,
    Modal,
    message,
    notification,
    Space,
    InputNumber,
    Form
} from "antd";
import _ from "lodash";
import editLeaveE from "../../api/employee/editLeaveEmployee";



export default function ModaleditLeaveE(prop) {
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [editData, setEditData] = useState({});
    const [name, setName] = useState('');
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    useEffect(() => {
        if (!_.isEmpty(prop.data)) {
            setEditData(prop.data);
            setName(prop.data.name);
            form.setFieldsValue({ max: prop.data.leave.max / 24 });
            setModalEditOpen(prop.modalOpen);
        }
    }, [prop]);
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };
    return (
        <>
            <Modal
                title={<div style={{ color: '#2196F3' }}>{`Edit Leave Day of ${name} ${prop.data.year}`}</div>}
                open={modalEditOpen}
                onCancel={() => {
                    setModalEditOpen(false);
                    prop.onClose();
                }}
                footer={null}
                closable={true}
                width={500}
            >
                {contextHolder}
                <Row justify="center" >
                    <Form form={form} name="advanced_search" layout='horizontal' style={{ marginTop: '20px' }} onFinish={(e) => {
                        editLeaveE(editData.id, e, (err, res) => {
                            if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                                message.success("Edit success");
                                prop.handleUpdated();
                                setModalEditOpen(false);
                                prop.onClose();
                            } else {
                                openNotificationWithIcon('error', 400, err ?? 'error not known');
                            }
                        });
                    }}>
                        <Row justify="center">
                            <Col>
                                <Space>
                                    {/* <Form.Item
                                        label='Used'
                                        name="used"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select Used!',
                                            },
                                        ]}
                                    >
                                        <InputNumber placeholder="used" step={24} min={0} max={parseInt(editData.leave?.max)} />
                                    </Form.Item> */}

                                    <Form.Item
                                        label='Max (Days)'
                                        name="max"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select Max!',
                                            },
                                        ]}
                                    >
                                        <InputNumber placeholder="max" step={1} min={0} />
                                    </Form.Item>
                                </Space>
                            </Col>
                        </Row>
                        <Row justify="space-between" style={{ padding: "0 25.57px", }}>
                            <Space>
                                <Button
                                    htmlType="button"
                                    style={{
                                        boxShadow: "unset",
                                        textShadow: "unset",
                                        borderRadius: "8px",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                    }}
                                    onClick={() => {
                                        prop.onClose();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    style={{
                                        boxShadow: "unset",
                                        textShadow: "unset",
                                        borderRadius: "8px",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                    }}
                                    htmlType="submit"

                                >
                                    Save Leave Day
                                </Button>
                            </Space>
                        </Row>
                    </Form>
                </Row>
            </Modal>
        </>
    );
}
