import React, { useEffect, useState } from 'react'
import { Space, Col, Row, Form, Input, message, notification, Select, Button, DatePicker, Modal } from 'antd';
import dayjs from 'dayjs';
import editEducation from '../../api/employee/editEducationEmployee'
export default function EditFormEducation(props) {
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [editData, setEditData] = useState({});
    const [name, setName] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };
    const [form] = Form.useForm();
    const formStyle = {
        maxWidth: 'none',
        padding: 10,
    };
    useEffect(() => {
        setEditData(props.data);
        setName(props.data.name);
        setModalEditOpen(props.modalOpen);
        form.setFieldsValue({
            name: props.data.name,
            branch: props.data.branch ?? null,
            studyDate: dayjs(props.data?.studyDate ?? dayjs()) ?? null,
            congratulationsDate: dayjs(props.data?.congratulationsDate ?? dayjs()) ?? null,
            institution: props.data.institution,
            score: props.data.score,
            educationId: props.data.educationId
        })
    }, [props]);


    return (
        <Modal
            title={`Edit Education of ${name}`}
            open={modalEditOpen}
            onCancel={() => {
                setModalEditOpen(false);
                form.resetFields();
                props.onClose();
            }}
            footer={null}
            closable={true}
            width={700}
        >
            {contextHolder}
            <Row>
                <Col>
                    <Form form={form} name="advanced_search" style={formStyle} onFinish={(e) => {
                        editEducation(editData.id, {...e,'employeeId':editData.employeeId}, (err, res) => {
                            if (err) {
                                openNotificationWithIcon('error', 400, err ?? 'error not known');
                            }
                            if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                                form.resetFields();
                                props.handleUpdated();
                                props.onClose();
                                message.success("This is a success add Education");
                            }
                            if (res.statusCode >= 400) {
                                openNotificationWithIcon('error', res.statusCode, res.message);
                            }
                        })
                    }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', flexWrap: 'wrap' }}>
                            <Row>
                                <Space>
                                    <Col >
                                        <Form.Item
                                            name={`branch`}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Input something!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Major Subject" />
                                        </Form.Item>
                                    </Col>
                                    <Col >
                                        <Form.Item
                                            name={`institution`}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Input something!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Educational Institutes" />
                                        </Form.Item>
                                    </Col>
                                </Space>
                            </Row>
                            <Row>
                                <Space>
                                    <Col >
                                        <Form.Item
                                            name="educationId"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select educationId!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                style={{
                                                    width: 120,
                                                }}
                                                placeholder="Level"
                                                options={props.datasEducation.map(t => { return { value: t.id, label: t.name } })}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col >
                                        <Form.Item
                                            name={`score`}
                                        >
                                            <Input placeholder="G.P.A" />
                                        </Form.Item>
                                    </Col>
                                    <Col >
                                        <Form.Item
                                            name={`studyDate`}
                                        >
                                            <DatePicker placeholder="Attended From" />
                                        </Form.Item>
                                    </Col>
                                    <Col >
                                        <Form.Item
                                            name={`congratulationsDate`}
                                        >
                                            <DatePicker placeholder="Attended Until " />
                                        </Form.Item>
                                    </Col>
                                </Space>
                            </Row>
                            <Row justify={'center'}>
                                <Col>
                                    <Button type="primary" htmlType="submit">
                                        Edit Education
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Modal>
    )
}