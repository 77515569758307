import React, { useState } from 'react'
import { Form, Input, Button, Row, Col, Typography, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import fetchlogin from '../../api/login'
const { Title } = Typography
function Login({ setToken, title }) {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    var navigate = useNavigate()
    return (
        <div style={{ position: 'relative', overflowX: 'clip', height: '100vh' }}>
            <Row
                align='middle'
                justify='center'
                style={{ textAlign: 'center', height: '25%' }}
            >
                <Col xs={24} sm={10} md={8} lg={8} xl={8} xxl={8}>
                    <Title
                        style={{
                            color: 'var(--ant-primary-color)',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '48px'
                        }}
                    >
                        Welcome!
                    </Title>
                </Col>
            </Row>
            <Row justify='center' style={{ height: '40%' }}>
                <Col xs={4} sm={4} md={8} lg={8} xl={9} xxl={9}>
                    <Row
                        style={{
                            alignItems: 'center',
                            height: '23.5px',
                            placeContent: 'end',
                            margin: '0 0 32px 0'
                        }}
                    >
                        <div
                            style={{
                                fontWeight: 'normal',
                                fontSize: '14px',
                                color: '#FF4D4F',
                                margin: '0 4px 0 0'
                            }}
                        >
                            *
                        </div>
                        <div
                            style={{
                                fontWeight: '500',
                                fontSize: '14px',
                                color: '#828282',
                                margin: '0 8px 0 0'
                            }}
                        >
                            Username :
                        </div>
                    </Row>
                    <Row
                        style={{
                            alignItems: 'center',
                            height: '23.5px',
                            placeContent: 'end'
                        }}
                    >
                        <div
                            style={{
                                fontWeight: 'normal',
                                fontSize: '14px',
                                color: '#FF4D4F',
                                margin: '0 4px 0 0'
                            }}
                        >
                            *
                        </div>
                        <div
                            style={{
                                fontWeight: '500',
                                fontSize: '14px',
                                color: '#828282',
                                margin: '0 8px 0 0'
                            }}
                        >
                            Password :
                        </div>
                    </Row>
                </Col>
                <Col
                    xs={16}
                    sm={16}
                    md={8}
                    lg={8}
                    xl={6}
                    xxl={6}
                    style={{ textAlign: 'center' }}
                >
                    <Form
                        layout='horizontal'
                        name='basic'
                        initialValues={{
                            remember: true
                        }}
                        onFinish={() => {
                            fetchlogin(
                                {
                                    username: username,
                                    password: password
                                },
                                (err, res) => {
                                    if (err) {
                                        message.error('error')
                                        return
                                    }
                                    if (res && res.statusCode === 200) {
                                        setToken({
                                            username: res.data.username,
                                            tierId: res.data.tierId,
                                            token: res.data.token
                                        })
                                        message.success('login is a success')
                                        setTimeout(() => {
                                            navigate('/overview')
                                        }, 500)
                                    } else {
                                        message.error(res?.message ?? 'fail')
                                    }
                                }
                            )
                        }}
                        autoComplete='off'
                    >
                        <Form.Item
                            name='username'
                            // label="Username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!'
                                }
                            ]}
                            onChange={e => {
                                setUsername(e.target.value)
                            }}
                            style={{
                                textAlign: 'start', fontWeight: 'normal',
                                fontSize: '14px',
                                color: '#FF4D4F',
                            }}
                        >
                            <Input className='inputbox' />
                        </Form.Item>

                        <Form.Item
                            name='password'
                            // label="Password &nbsp;"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!'
                                }
                            ]}
                            onChange={e => {
                                setPassword(e.target.value)
                            }}
                            style={{
                                textAlign: 'start', fontWeight: 'normal',
                                fontSize: '14px',
                                color: '#FF4D4F',
                            }}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Row align='middle' justify='center'>
                            <Col span={24} align='center'>
                                <Form.Item>
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        style={{
                                            borderRadius: '8px',
                                            color: '#FFFFFF',
                                            margin: '0 0 13px 0',
                                            fontWeight: '500',
                                            boxShadow: "unset",
                                            textShadow: "unset",
                                        }}
                                    >
                                        Login
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col xs={4} sm={4} md={8} lg={8} xl={9} xxl={9}></Col>
            </Row>
           
        </div>
    )
}

export default Login
