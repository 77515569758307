const editDepartment = (id, data, cb) => {
  let token = sessionStorage.getItem("token");
  token = JSON.parse(token);
  fetch(`${process.env.REACT_APP_API_URL_HELLOTEAM}api/v1/department/update/${id}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token.token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((json) => cb(null, json))
    .catch((err) => cb(err, null));
};

export default editDepartment;
