import moment from 'moment'

export default async function durationTime(startDate, endDate, is_overtime) {
    var startDate_moment = moment(startDate)
    var endDate_moment = moment(endDate)
    var nowDate = moment()
    let endDateCal = endDate ? endDate_moment : nowDate;
    var duration = moment.duration(endDate_moment.diff(startDate_moment));
    return { months: duration.months(), years: duration.years(), asDays: duration.asDays(), days: duration.days(), asHours: duration.asHours(), hours: duration.hours(), minutes: duration.minutes() }
}
