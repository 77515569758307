import React, { useState, useEffect, } from 'react'
export default function Clock(props) {
    const [activedateMenu, setActivedateMenu] = useState(props.activedateMenu)
    useEffect(() => {
        setActivedateMenu(props.activedateMenu)
    }, [props])
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Clock">
                <path id="Vector" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke={activedateMenu === 'overtime' ? "#2196F3" : "#818283"} stroke-width="2" stroke-miterlimit="10" />
                <path id="Vector_2" d="M12 6.75V12H17.25" stroke={activedateMenu === 'overtime' ? "#2196F3" : "#818283"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
        </svg>

    )
}
