import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Input, message } from 'antd'
import { SearchOutlined, PlusOutlined, } from '@ant-design/icons'
import { useParams, useNavigate } from 'react-router-dom'

import TableEducation from '../../components/employee/tableeducationEmployee';
import AddFormEducation from '../../components/employee/addFormEducation';

import getEducation from '../../api/education/getByemployee';
import getALLEducation from '../../api/education/getAllEducation';

export default function EducationEmployee(props) {
    const [isFetch, setIsfetch] = useState(false);
    const [addButton, setAddbutton] = useState(false);
    const [isLoading, setIsloading] = useState(true);
    const [datas, setDatas] = useState([]);
    const [datasEducation, setDatasEducation] = useState([]);
    const [datasFilter, setDatasFilter] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        if (!isFetch) {
            fetch_data(id);
            getALLEducation((err, res) => {
                if (err) {
                    console.log(err);
                }
                if (!err && res.statusCode === 200) {
                    setDatasEducation(res.data);
                } else {
                    message.error(res?.message ?? 'fail')
                }
            })
        }
        return () => {
            // cleanup
        };
    }, []);
    const fetch_data = (id) => {
        getEducation(id, (err, res) => {
            if (err) {
                console.log(err);
            }
            if (!err && res.statusCode === 200) {
                setDatas(res.data);
                setDatasFilter(res.data);
                setIsfetch(true);
                setIsloading(false);
            } else {
                message.error(res?.message ?? 'fail')
            }
        })
    }
    return (
        <div style={{ padding: '20px', margin: '20px 15px 15px 20px', background: '#FFF', borderRadius: '16px' }}>
            <Row align='middle' justify='space-between' style={{ marginBottom: '10px' }}>
                <Col span={10} style={{ color: "var(--ant-primary-color)" }}>
                    Education
                </Col>
                <Col span={14}>
                    <Row style={{ flexWrap: 'nowrap', justifyContent: 'flex-end', gap: '8px' }}>
                        {!addButton && (
                            <Input
                                suffix={
                                    <SearchOutlined style={{ color: 'rgba(79, 79, 79, 0.5)' }} />
                                }
                                className='ant-inputsearch search-border'
                                placeholder={`Search....`}
                                style={{
                                    margin: '0px 0px 0px 10px',
                                    borderRadius: '8px',
                                    // border: '1px solid var(--ant-primary-color)',
                                    maxWidth: '200px'
                                }}
                                onChange={e => {
                                    const currValue = e.target.value
                                    const filteredData = datasFilter.education_employee_maps.filter(entry =>
                                        Object.keys(entry).filter((k) => k).some(s => {
                                            return String(entry[s])
                                                .toLowerCase()
                                                .includes(currValue.toLowerCase())
                                        })
                                    )
                                    // console.log(filteredData);
                                    // console.log(Object.assign(datas,{ 'education_employee_maps': filteredData }));
                                    // setDatas(Object.assign(datas,{ 'education_employee_maps': filteredData }))
                                }}
                            />
                        )}
                        <Button
                            loading={!isFetch}
                            type={!addButton ? 'primary' : ''}
                            style={{
                                borderRadius: '8px',
                                fontWeight: '500',
                                boxShadow: "unset",
                                textShadow: "unset",
                            }}
                            onClick={() => { setAddbutton(!addButton); setDatas(datasFilter) }}
                        >
                            {!addButton && <PlusOutlined />}
                            {!addButton ? 'Add Education' : 'Cancel'}
                        </Button>
                        <Button
                            type={'primary'}
                            onClick={() => navigate('/employee')}
                        >
                            Back
                        </Button>
                    </Row>
                </Col>
            </Row>
            {addButton && (
                <AddFormEducation
                    onClose={() => { setAddbutton(!addButton); }}
                    datasEducation={datasEducation}
                    handleUpdated={() => {
                        setIsloading(true);
                        setTimeout(() => {
                            fetch_data(id);
                        }, 1000);
                    }}
                />
            )}
            {isFetch && (
                <TableEducation
                    data={datas} isLoading={isLoading}
                    datasEducation={datasEducation}
                    handleUpdated={() => {
                        setIsloading(true);
                        setTimeout(() => {
                            fetch_data(id);
                        }, 1000);
                    }}
                />
            )}
        </div>
    )
}
