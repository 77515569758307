import React, { useEffect, useState } from "react";
import { Button, Row, Modal,notification } from "antd";

import deleteTitle from "../../api/title/deleteTitle";

export default function ModaldeleteTitle(prop) {

  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [api, contextHolder] = notification.useNotification();
  useEffect(() => {
    setModalOpen(prop.modalOpen);
    setName(prop.data.name);
    setId(prop.data.id);
  }, [prop]);
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };
  return (
    <>
      <Modal
        title={`Delete ${name}`}
        open={modalOpen}
        footer={null}
        onCancel={() => {
          prop.onClose();
        }}
        closable={true}
      >
        {contextHolder}
        <div
          style={{
            fontSize: "16px",
            color: "#82828",
            fontWeight: "normal",
          }}
        >
          are you sure ?
        </div>
        <Row style={{ justifyContent: "end", padding: "0" }}>
          <Button
            htmlType="button"
            style={{
              boxShadow: "unset",
              textShadow: "unset",
              borderRadius: "8px",
              fontSize: "14px",
              margin: "0 0 0px 10px",
              fontWeight: "500",
            }}
            onClick={() => {
              prop.onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="button"
            style={{
              boxShadow: "unset",
              textShadow: "unset",
              borderRadius: "8px",
              fontSize: "14px",
              margin: "0 0 0px 10px",
              fontWeight: "500",
            }}
            danger
            onClick={() => {
              deleteTitle(id, (err, res) => {
                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                  prop.handleUpdated();
                  prop.onClose();
                } else {
                  openNotificationWithIcon('error', 400, err ?? 'error not known');
                }
              });
            }}
          >
            Delete
          </Button>
        </Row>
      </Modal>
    </>
  );
}
