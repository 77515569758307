import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Modal,
  message,
  Switch,
  Select,
  notification
} from "antd";
import _ from 'lodash';

import editGroupPermisstion from "../../api/grouppermisstion/editGroupPermisstion"


export default function ModaleditGroupPermisstion(prop) {
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [is_disable, setIsdiosable] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [name, setName] = useState('');
  const [form] = Form.useForm();
  useEffect(() => {
    if (!_.isEmpty(prop.data)) {
      if (prop.data.detail.children.length > 0 || prop.data.detail.pestentId !== null) {
        setIsdiosable(true);
      }
      setName(prop.name);
      setModalEditOpen(prop.modalOpen);
      form.setFieldsValue({ is_notify: prop.data.detail.is_notify, is_appove: prop.data.detail.is_appove, name: prop.data.label, level: prop.data.detail.level });
    }

  }, [prop]);
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };
  return (
    <>
      <Modal
        title={<div style={{ color: '#2196F3' }}>{`Edit Group of ${name}`}</div>}
        open={modalEditOpen}
        onCancel={() => {
          setModalEditOpen(false);
          form.resetFields();
          prop.onClose();
        }}
        footer={null}
        closable={true}
        width={550}
      >
        {contextHolder}
        <Form
          autoComplete="off"
          layout="horizontal"
          name="advanced_search"
          size="large"
          form={form}
          onFinish={(e) => {
            editGroupPermisstion(prop.data.detail.id, e, (err, res) => {
              if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                prop.handleUpdated();
                setModalEditOpen(false);
                prop.onClose();
                message.success("Edit success");
                form.resetFields();
              } else {
                openNotificationWithIcon('error', 400, err ?? 'error not known');
              }
            }
            );
          }}
        >
          <Row style={{ alignItems: "start" }}>
            <Col span={24} style={{ margin: "0 25px 0 0" }}>
              <Form.Item
                label={`Name`}
                autoComplete="off"
                name="name"
                rules={[
                  {
                    required: true,
                    message: `Input something!`
                  },
                ]}
              >
                <Input
                  showCount
                  className="inputbox"
                  placeholder={`Name`}
                  style={{ borderRadius: "8px" }}
                  maxLength={50}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item
                name="is_appove"
                label="Approve"
                valuePropName='checked'
                rules={[
                  {
                    required: true,
                    message: 'Input something!',
                  },
                ]} >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="is_notify"
                label="Notification"
                valuePropName='checked'
                rules={[
                  {
                    required: true,
                    message: 'Input something!',
                  },
                ]}>
                <Switch />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="level" rules={[
                {
                  required: true,
                  message: `Input something!`
                },
              ]}>
                <Select placeholder='Level' disabled={is_disable}>
                  <Select.Option value={1}>1</Select.Option>
                  <Select.Option value={2}>2</Select.Option>
                  <Select.Option value={3}>3</Select.Option>
                  <Select.Option value={4}>4</Select.Option>
                  <Select.Option value={5}>5</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" style={{ pediting: "0 25.57px", gap: "8px" }}>
            <Button
              htmlType="button"
              style={{
                boxShadow: "unset",
                textShadow: "unset",
                borderRadius: "8px",
                fontSize: "14px",
                fontWeight: "500",
              }}
              onClick={() => {
                form.resetFields();
                prop.onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              style={{
                boxShadow: "unset",
                textShadow: "unset",
                borderRadius: "8px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Save Group Permission
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
