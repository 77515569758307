import React, { useEffect, useState } from 'react'
import TableDetail from '../../components/employee/detailTableEmployee';
import { Row, Col, Button, message, DatePicker } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { CSVLink } from "react-csv";
import dayjs from 'dayjs'
import _ from 'lodash';
import getDetailEmployee from '../../api/employee/getDetail';
export default function Employeedetail(props) {
    const [isFetch, setIsfetch] = useState(false);
    const [startDate, setStartdate] = useState(dayjs().add(-15, 'day'))
    const [endDate, setEnddate] = useState(dayjs());
    const [employeeDetail, setEmployeedetail] = useState({
        "employeeId": "-",
        "firstname_th": "-",
        "lastname_th": "-",
        "nickname": '-',
        "positions": '-',
        "bu": '-'
    });
    const [calenDar, setCalendar] = useState([]);
    const [calenDarFilter, setCalendarFilter] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();
    useEffect(() => {
        if (!isFetch) {
            fetchData(id);
        }

        return () => {
            // cleanup
        };
    }, []);
    const fetchData = (id) => {
        getDetailEmployee(id, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), (err, res) => {
            if (err) {
                console.log(err);
            }
            if (!err && res.statusCode === 200) {
                let employee = res.data;
                employee["title"] = employee.title?.name ?? '-'
                employee["bu"] = employee.positions?.Sections?.Departments?.business_groups?.name ?? '-'
                employee["positions"] = employee.positions?.name ?? '-'
                setEmployeedetail(employee);
                let calendar_tmp = [...res.calendar];
                calendar_tmp = calendar_tmp.map(c => {
                    return {
                        "name": employee.firstname_th + ' ' + employee.lastname_th,
                        "day": c.day,
                        "dayname": c.dayname,
                        "worktime_in": (!_.isEmpty(c.work_time.in)) ? dayjs(c.work_time.in.createdAt).format('HH:mm') : '-',
                        "worktime_in_localtion": (!_.isEmpty(c.work_time.in)) ? c.work_time.in.location : '-',
                        "worktime_out": (!_.isEmpty(c.work_time.out)) ? dayjs(c.work_time.out.createdAt).format('HH:mm') : '-',
                        "worktime_out_localtion": (!_.isEmpty(c.work_time.out)) ? c.work_time.out.location : '-',
                        "worktime_total": c.work_time.sumtotal,
                        "overtime_total": c.over_time_sum !== 0 ? c.over_time_sum : '-',
                        "leave_type": c.leaves_type,
                        "leave_duration": c.leaves_sum,
                        "manually": c.work_time.manually
                    }
                });
                setCalendar(calendar_tmp);
                setCalendarFilter(calendar_tmp);
                setIsfetch(true);
            } else {
                message.error(res?.message ?? 'fail')
            }
        })
    }
    return (
        <div style={{ padding: '20px', margin: '20px 15px 15px 20px', background: '#FFF', borderRadius: '16px' }}>
            <Row align='middle' justify='space-between' style={{ marginBottom: '10px' }}>
                <Col span={10} >
                    <Row style={{ color: "#2196F3", marginBottom: '10px', fontSize: '1.25rem' }}>{employeeDetail.title}{employeeDetail.firstname_th} {employeeDetail.lastname_th}</Row>
                    <Row>Employee ID : {employeeDetail.employeeId}</Row>
                    <Row>Position : {employeeDetail.positions}</Row>
                    <Row>Business Unit : {employeeDetail.bu}</Row>
                </Col>
                <Col span={14}>
                    <Row style={{ flexWrap: 'nowrap', justifyContent: 'flex-end', gap: '8px', marginBottom: '10px' }}>
                        <Button
                            loading={!isFetch}
                            type={'primary'}
                            style={{
                                borderRadius: '8px',
                                fontWeight: '500',
                                boxShadow: "unset",
                                textShadow: "unset",
                            }}
                            onClick={() => navigate('/employee')}
                        >
                            Back
                        </Button>
                    </Row>
                    <Row style={{ flexWrap: 'nowrap', justifyContent: 'flex-end', gap: '8px' }}>
                        <DatePicker onChange={(e) => { setStartdate(e) }} defaultValue={startDate} />
                        <DatePicker onChange={(e) => { setEnddate(e) }} defaultValue={endDate} />
                        <Button
                            loading={!isFetch}
                            type={'primary'}
                            style={{
                                borderRadius: '8px',
                                fontWeight: '500',
                                boxShadow: "unset",
                                textShadow: "unset",
                            }}
                            onClick={() => { fetchData(id) }}
                        >
                            Submit
                        </Button>
                        <Button
                            loading={!isFetch}
                            style={{
                                borderRadius: '8px',
                                color: 'var(--ant-primary-color)',
                                border: '1px solid var(--ant-primary-color)',
                                // padding: '10px',
                                //   margin: '20px 10px',
                                fontWeight: '500',
                                boxShadow: "unset",
                                textShadow: "unset",
                            }}
                        >
                            <CSVLink data={calenDar} filename={`${employeeDetail.firstname_th}_${employeeDetail.lastname_th}_${dayjs().format('YYYY-MM-DD')}.csv`}>
                                <UploadOutlined /> Export
                            </CSVLink>
                        </Button>
                    </Row>
                </Col>
            </Row>
            <TableDetail data={calenDar} isFetch={isFetch} />
        </div>
    )
}
