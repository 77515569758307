import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Timeline,
    Modal,
    Space
} from "antd";
import _ from 'lodash';


export default function ModalTimelineOvertime(prop) {
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [editData, setEditData] = useState({
        "detail": {
            "Name[TH]": "-",
            "Start Date": "-",
            "Check In": "-",
            "Location In": "-",
            "End Date": "-",
            "Check Out": "-",
            "Location Out": "-",
            "Summary": "-",
        },
        "response": {
            "Request Status ": "-",
            "Response When": "-",
            "Response By ": "-",
            "Response Reason ": "-",
        },
    });
    const [color, setColor] = useState('');
    const [colorHr, setColor_hr] = useState('');
    useEffect(() => {
        if (!_.isEmpty(prop.data)) {
            let status_name = '';
            let res_reason = '-';
            if (prop.data.status === 0) {
                status_name = 'ไม่อนุมัติ';
                setColor("red")
                res_reason = prop.data.reason_reject
            } else if (prop.data.status === 1) {
                status_name = 'เริ่มต้น'
            } else if (prop.data.status === 2) {
                status_name = 'รออนุมัติ'
                setColor("#2196F3")
            } else if (prop.data.status === 3) {
                status_name = 'อนุมัติ'
                setColor("green")
                res_reason = prop.data.reason
            }
            else if (prop.data.status === -1) {
                status_name = 'ยกเลิกโดยผู้ใช้งาน'
                setColor("red")
            } else if (prop.data.status === -2) {
                status_name = 'ยกเลิกโดยฝ่ายบุคคล';
                setColor("red")
                res_reason = prop.data.reason
            }
            var sumtotal = '-'
            if (String(prop.data.sumtotal).split('.').length > 1) {
                sumtotal = String(prop.data.sumtotal).split('.')[0] + "  ชั่วโมง" + ' 30 นาที'
            } else { sumtotal = String(prop.data.sumtotal) + "  ชั่วโมง" + ' 0 นาที' }
            let tmp = {
                "detail": {
                    "Name[TH]": prop.data.name,
                    "Start Date": prop.data.startDate,
                    "Check In": prop.data.checkin,
                    "Location In": prop.data.locationin,
                    "End Date": prop.data.endDate,
                    "Check Out": prop.data.checkout,
                    "Location Out": prop.data.locationout,
                    "Summary": sumtotal,
                },
                "response": {
                    "Request Status": status_name,
                    "Response When": prop.data.responsewhen,
                    "Response By": prop.data.appoved_by,
                    "Response Reason ": res_reason,
                },
            }
            if (prop.data.response_hr_when !== 'Invalid date') {
                if (prop.data.status_hr === 1) {
                    setColor_hr('green');
                } else { setColor_hr('red'); }
                tmp.response_hr = {
                    "Request Status": prop.data.status_name_hr,
                    "Response When": prop.data.response_hr_when,
                    "Response By": "HR",
                    "Response Reason": prop.data.reason_hr,
                }
            }
            setEditData(tmp)
        }

        setModalEditOpen(prop.modalOpen);
    }, [prop]);

    return (
        <>
            <Modal
                title={`Overtime Detail`}
                open={modalEditOpen}
                onCancel={() => {
                    setModalEditOpen(false);
                    prop.onClose();
                }}
                footer={null}
                closable={true}
                width={500}
            >
                <Timeline
                    style={{ margin: '20px' }}
                    items={
                        Object.keys(editData).map((d, i) => {
                            var colorr = '';
                            if (i === 0) {
                                colorr = '#2196F3'
                            } else if (i === 1) {
                                colorr = color
                            } else if (i === 2) {
                                colorr = colorHr
                            }
                            return {
                                color: colorr,
                                children: (
                                    <>
                                        <span style={{ color: 'rgba(33, 150, 243, 0.75)', marginBottom: '10px', fontWeight: 500 }}>
                                            {i === 0 && 'Detail'}
                                            {i === 1 && 'Response'}
                                            {i === 2 && 'Response HR'}
                                        </span>
                                        {Object.keys(editData[d]).map(detail => (
                                            <Row>
                                                <Col span={10}>{detail}</Col>
                                                <Col span={14}>{editData[d][detail]}</Col>
                                            </Row>
                                        ))}
                                    </>
                                ),
                            }
                        })}
                />
            </Modal>
        </>
    );
}
