import React, { useState, useEffect, } from 'react'
export default function Wrench(props) {
    const [activedateMenu, setActivedateMenu] = useState(props.activedateMenu)
    useEffect(() => {
        setActivedateMenu(props.activedateMenu)
    }, [props])
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Wrench">
                <path id="Vector" d="M12.2703 15.107L12.3125 15.1253C13.5465 15.6614 14.913 15.8148 16.2351 15.5658C17.5573 15.3168 18.7744 14.6769 19.7289 
                13.7287C20.6742 12.7887 21.3157 11.5868 21.5706 10.2782C21.8254 8.96973 21.6818 7.61486 21.1584 6.38883L21.1583 6.38871C21.1143
                 6.28504 21.0456 6.19377 20.9581 6.12289C20.8706 6.05201 20.767 6.0037 20.6565 5.9822C20.5459 5.96069 20.4318 5.96666 20.3241
                  5.99956C20.2164 6.03246 20.1184 6.0913 20.0388 6.1709L16.3887 9.82102L16.365 9.84467L16.3322 9.83811L14.5645 9.48453L14.5236 
                  9.47636L14.5155 9.43551L14.1619 7.66771L14.1553 7.63491L14.179 7.61126L17.829 3.96124C17.9087 3.8816 17.9675 3.78363 18.0004 
                  3.67593C18.0334 3.56823 18.0393 3.4541 18.0178 3.34355C17.9963 3.23301 17.948 3.12943 17.8771 3.04192C17.8063 2.95442 17.715 
                  2.88565 17.6113 2.84166C16.3781 2.31921 15.0164 2.17896 13.7026 2.43905C12.3888 2.69914 11.1832 3.3476 10.2421 4.3005C9.30092 
                  5.2534 8.66745 6.46686 8.42365 7.78382C8.17986 9.10077 8.33698 10.4606 8.87468 11.6872L8.89319 11.7295L8.85829 11.7596L3.20976 
                  16.6385C3.2097 16.6386 3.20964 16.6386 3.20958 16.6387L12.2703 15.107ZM12.2703 15.107L12.2403 15.1418L7.36129 20.7904C7.35032 20.8031 
                  7.33892 20.8154 7.3271 20.8272C6.77611 21.3776 6.02911 21.6867 5.25032 21.6865C4.4715 21.6863 3.72464 21.3768 3.17393 20.8261C2.62322 
                  20.2754 2.31374 19.5285 2.31354 18.7497C2.31333 17.9709 2.62241 17.2239 3.17281 16.6729L12.2703 15.107Z"
                    fill={activedateMenu === 'title' || activedateMenu === 'education' || activedateMenu === 'position'
                        || activedateMenu === 'section' || activedateMenu === 'department' || activedateMenu === 'businessunit' || activedateMenu === 'groupposition' ? "#2196F3" : "#444444"} stroke="#444444" stroke-width="0.125" />
            </g>
        </svg>
    )
}
