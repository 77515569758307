
import React, { useState, useEffect, } from 'react'
export default function NavigationArrow(props) {
    const [activedateMenu, setActivedateMenu] = useState(props.activedateMenu)
    useEffect(() => {
        setActivedateMenu(props.activedateMenu)
    }, [props])
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="NavigationArrow">
                <path id="Vector" d="M3.50531 4.45917L8.99953 20.3314C9.05127 20.4808 9.14893 20.6101 9.27856 20.7007C9.4082 20.7913 9.56316 20.8387 9.7213 20.8359C9.87944 20.8332 10.0327 20.7805 10.1591 20.6854C10.2855 20.5904 10.3786 20.4578 10.4251 20.3066L12.6334 13.1295C12.6694 13.0126 12.7334 12.9062 12.8199 12.8197C12.9064 12.7332 13.0128 12.6692 13.1297 12.6332L20.3068 
                10.4249C20.458 10.3784 20.5906 10.2853 20.6856 10.1589C20.7807 10.0325 
                20.8334 9.87923 20.8361 9.72109C20.8389 9.56295 20.7916 9.40799 20.7009 
                9.27835C20.6103 9.14872 20.481 9.05106 20.3316 8.99932L4.45938 3.5051C4.32638 
                3.45906 4.1831 3.45137 4.04593 3.48292C3.90877 3.51447 3.78324 3.58398 3.68372 
                3.68351C3.5842 3.78303 3.51468 3.90855 3.48314 4.04572C3.45159 4.18289 3.45927 4.32616 3.50531 4.45917V4.45917Z" stroke={activedateMenu === 'overview' ? "#2196F3" : "#818283"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
        </svg>
    )
}
