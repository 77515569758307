import { createSlice } from '@reduxjs/toolkit'
export const businessunitSlice = createSlice({
  name: 'businessunit',
  initialState: { dataBU: [], dataDepartment: [], dataSection: [], dataTitel: [], dataEmployee_type: [], dataPosition: [], dataGroup_position: [], },
  reducers: {
    editdataBU: (state, action) => {
      let tmp = action.payload
      state.dataBU = [...tmp]
    },
    editdataDepartment: (state, action) => {
      let tmp = action.payload
      state.dataDepartment = [...tmp]
    },
    editdataSection: (state, action) => {
      let tmp = action.payload
      state.dataSection = [...tmp]
    },
    editdataTitle: (state, action) => {
      let tmp = action.payload
      state.dataTitel = [...tmp]
    },
    editdataEmployee_type: (state, action) => {
      let tmp = action.payload
      state.dataEmployee_type = [...tmp]
    },
    editdataPosition: (state, action) => {
      let tmp = action.payload
      state.dataPosition = [...tmp]
    },
    editdataGroup_position: (state, action) => {
      let tmp = action.payload
      state.dataGroup_position = [...tmp]
    },
  }
})

export const { editdataBU, editdataDepartment, editdataSection, editdataTitle, editdataEmployee_type, editdataGroup_position, editdataPosition } = businessunitSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getBusinessUnit = state => state.businessUnit.dataBU
export const getDepartment = state => state.businessUnit.dataDepartment
export const getSection = state => state.businessUnit.dataSection
export const getTitle = state => state.businessUnit.dataTitel
export const getEmployee_type = state => state.businessUnit.dataEmployee_type
export const getPosition = state => state.businessUnit.dataPosition
export const getGroup_position = state => state.businessUnit.dataGroup_position

export default businessunitSlice.reducer
