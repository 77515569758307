import React, { useEffect, useState } from 'react'
import { Row, Col, Button, message } from 'antd'
import { PlusOutlined,  } from '@ant-design/icons'
import dayjs from 'dayjs';

import TableBroadcast from '../../../components/broadcast/tableBroadcast';
import AddFormBroadcast from '../../../components/broadcast/addFormBroadcast';

import getAllBroadcast from '../../../api/broadcast/getAllBroadcast';

export default function Broadcast(props) {
    const [isFetch, setIsfetch] = useState(false);
    const [addButton, setAddbutton] = useState(false);
    const [isLoading, setIsloading] = useState(true);
    const [datas, setDatas] = useState([]);
    const [datasFilter, setDatasFilter] = useState([]);
    useEffect(() => {
        if (!isFetch) {
            fetch_data()
        }
        return () => {
            // cleanup
        };
    }, []);
    const fetch_data = () => {
        getAllBroadcast((err, res) => {
            if (err) {
                console.log(err);
            }
            if (!err && res.statusCode === 200) {

                setDatas(res.data.map((d, i) => {
                    return {
                        ...d, 'key': i + 1,
                        "dateName": dayjs(d.dateBroadcast).format('DD/MM/YYYY HH:mm'),
                        'exmple': d.Messages.reduce((accumulator, currentValue, index) => {
                            if (index !== 0) {
                                return accumulator.substring(0, 10) + ' ,' + currentValue.detail.substring(0, 10)
                            } else {
                                return currentValue.detail.substring(0, 10)
                            }

                        }, '')
                    }
                }));
                setDatasFilter(res.data.map((d, i) => {
                    return {
                        ...d, 'key': i + 1, "dateName": dayjs(d.dateBroadcast).format('DD/MM/YYYY HH:mm'),
                        'exmple': d.Messages.reduce((accumulator, currentValue, index) => {
                            if (index !== 0) {
                                return accumulator.substring(0, 10) + ' ,' + currentValue.detail.substring(0, 10)
                            } else {
                                return currentValue.detail.substring(0, 10)
                            }

                        }, '')
                    }
                }));
                setIsfetch(true);
                setIsloading(false);
            } else {
                message.error(res?.message ?? 'fail')
            }
        })
    }

    return (
        <div style={{ padding: '20px', margin: '20px 15px 15px 20px', background: '#FFF', borderRadius: '16px' }}>
            <Row align='middle' justify='space-between' style={{ marginBottom: '10px' }}>
                <Col span={10} style={{ color: "#2196F3" }}>
                    {addButton ? 'Add Broadcast ' : ''}
                </Col>
                <Col span={14}>
                    <Row style={{ flexWrap: 'nowrap', justifyContent: 'flex-end', gap: '8px' }}>
                        <Button
                            loading={!isFetch}
                            type={!addButton ? 'primary' : ''}
                            style={{
                                borderRadius: '8px',
                                // color: '#4F4F4F',
                                // padding: '10px',
                                //   margin: '20px 10px',
                                fontWeight: '500',
                                boxShadow: "unset",
                                textShadow: "unset",
                            }}
                            onClick={() => { setAddbutton(!addButton); setDatas(datasFilter) }}
                        >
                            {!addButton && <PlusOutlined />}
                            {!addButton ? 'Add Broadcast ' : 'Cancel'}
                        </Button>
                    </Row>
                </Col>
            </Row>
            {addButton && (
                <AddFormBroadcast onClose={() => { setAddbutton(!addButton); }}
                    handleUpdated={() => {
                        setIsloading(true);
                        setTimeout(() => {
                            fetch_data();
                        }, 1000);
                    }}
                />
            )}
            {(isFetch && !addButton) && (
                <TableBroadcast data={datas} isLoading={isLoading}
                    handleUpdated={() => {
                        setIsloading(true);
                        setTimeout(() => {
                            fetch_data();
                        }, 1000);
                    }}
                />
            )}
        </div>
    )
}
