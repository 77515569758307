import React, { useEffect } from 'react'
import { Col, Row, Form, Input, message, notification, Button, Select, DatePicker } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import addBroadcast from '../../api/broadcast/addBroadcast'
export default function AddFormBroadcast(props) {
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };
    const [form] = Form.useForm();
    const formStyle = {
        padding: 24,
    };
    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };
    useEffect(() => {
        // effect
        form.setFieldsValue({ enable: true });
        return () => {
            // cleanup
        };
    }, []);
    return (
        <div style={{ padding: '20px', margin: '20px 15px 15px 20px', backgroundColor: "#FFF" }}>
            {contextHolder}
            <Row >
                <Col span={4}></Col>
                <Col span={16}>
                    <Form form={form} name="advanced_search" style={formStyle}   {...layout} onFinish={(e) => {
                        if (e.Messages === undefined) {
                            openNotificationWithIcon('error', 400, 'Plesse Add Messages');
                            return
                        }
                        addBroadcast(e, (err, res) => {
                            if (err) {
                                openNotificationWithIcon('error', 400, err ?? 'error not known');
                            }
                            if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                                form.resetFields();
                                props.handleUpdated();
                                props.onClose();
                                message.success("This is a success add Broadcast");
                            }
                            if (res.statusCode >= 400) {
                                openNotificationWithIcon('error', res.statusCode, res.message);
                            }
                        })
                    }}>
                        <Row justify={'center'} >
                            <Col span={8}>
                                <Form.Item
                                    label={'Date'}
                                    name={`dateBroadcast`}

                                    rules={[
                                        {
                                            required: true,
                                            message: 'Input something!',
                                        },
                                    ]}
                                >
                                    <DatePicker showTime format="YYYY-MM-DD HH:mm" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={'Status'}
                                    name={`status`}
                                    style={{ minWidth: '100px' }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Input something!',
                                        },
                                    ]}
                                >
                                    <Select>
                                        <Select.Option value={1}>Ready to send</Select.Option>
                                        <Select.Option value={2}>Draft</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={'center'} >
                            <Col span={24}>
                                <Form.List name="Messages" layout="vertical">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Row justify={'center'} style={{ gap: '8px' }}>
                                                    <Col
                                                        key={key}
                                                        span={12}
                                                    >
                                                        <Form.Item
                                                            label={"Messages NO." + (name + 1) }
                                                            {...restField}
                                                            name={[name, 'detail']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Message_" + (name + 1),
                                                                },
                                                            ]}
                                                        >
                                                            <Input.TextArea placeholder={"Message_" + (name + 1)} showCount maxLength={500} />
                                                        </Form.Item>


                                                    </Col>
                                                    <Col span={2}>
                                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                                    </Col>
                                                </Row>
                                            ))}
                                            <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', flexWrap: 'wrap', }}>
                                                <Row justify={'center'}>
                                                    <Col>
                                                        {fields.length < 3 && (
                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{width:'500px'}}>
                                                                Add Messages
                                                            </Button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    )}
                                </Form.List>
                            </Col>
                        </Row>
                        <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', flexWrap: 'wrap', marginTop: '10px' }}>
                            <Row justify={'center'}>
                                <Col>
                                    <Button type="primary" htmlType="submit">
                                        Add Broadcast
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Col>
                <Col span={4}></Col>
            </Row>
        </div >
    )
}