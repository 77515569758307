import React, { useState, useEffect, } from 'react'

export default function Browser(props) {
    const [activedateMenu, setActivedateMenu] = useState(props.activedateMenu)
    useEffect(() => {
        setActivedateMenu(props.activedateMenu)
    }, [props])
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Browser">
                <path id="Vector" d="M2.31322 8.98665H2.31323V8.98549V5.25004C2.31368 4.86892 2.46527 4.50353 2.73477 4.23404C3.00427 3.96453 3.36967 3.81294 3.7508 3.8125L20.2507 3.8125C20.6318 3.81295 20.9972 3.96454 21.2667 4.23404C21.5362 4.50354 21.6878 4.86894 
                21.6882 5.25007V18.7499C21.6878 19.1311 21.5362 19.4965 21.2667 19.766C20.9972 20.0355 20.6318 20.1871 20.2507 20.1875H3.7508C3.36967 
                20.1871 3.00427 20.0355 2.73477 19.766C2.46527 19.4965 2.31367 19.1311 2.31323 18.7499L2.31323 9.01451H2.31324L2.31322 9.01335C2.31315 
                9.00931 2.31286 9.00544 2.31271 9.00337L2.31267 9.0028C2.3125 9.00034 2.3125 8.99996 2.3125 9C2.3125 9.00004 2.3125 8.99966 2.31267 
                8.9972L2.31271 8.99663C2.31286 8.99456 2.31315 8.99069 2.31322 8.98665ZM20.3132 5.25L20.3132 
                5.1875H20.2507H3.75073H3.68823V5.25V8.25V8.3125H3.75073H20.2509H20.3134L20.3134 8.25L20.3132 5.25Z" fill={activedateMenu === 'overview' 
                || activedateMenu === 'employee' || activedateMenu === 'worktime' || activedateMenu === 'leave' 
                || activedateMenu === 'overtime' ? "#2196F3" : "#444444"} stroke="#2196F3" stroke-width="0.125" />
            </g>
        </svg>
    )
}
