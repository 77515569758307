import React, { useEffect, useState } from 'react'
import { Space, Table, Tag, Tooltip } from 'antd';
import { EditFilled, BookOutlined, CalendarFilled, FileFilled, ClockCircleOutlined, SlidersFilled } from '@ant-design/icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import _ from 'lodash'

import Component_wroktime from './workDayEmployee'
export default function Tableemployee(props) {
    const [modalWorktimeOpen, setModalWorktimeOpen] = useState(false);
    const [objOne, setObjone] = useState({});
    const [current, setCurrent] = useState(1);
    const [isLoading, setIsloading] = useState(true);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const params = {};
    const columns = [
        {
            title: 'No.',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Employee',
            dataIndex: 'employeeId',
            key: 'employeeId',
            sorter: (a, b) => a.employeeId - b.employeeId
        },
        {
            title: 'Firstname - Lastname [TH]',
            dataIndex: 'firstname_th',
            key: 'firstname_th',
            render: (_, record) => {
                let name_title = record.title.name + record.firstname_th + ' ' + record.lastname_th
                return name_title
            },
        },
        {
            title: 'Nickname',
            dataIndex: 'nickname',
            key: 'nickname',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Status',
            dataIndex: 'employee_status',
            key: 'employee_status',
            render: (_, record) => {
                if (record.employee_status?.id) {
                    if (record.employee_status.id === 1) {
                        return <Tag color="success">{'Active'}</Tag>
                    } else {
                        return <Tag color="red">{'Inactive'}</Tag>
                    }
                } else {
                    return <Tag color="default">{'Unknown'}</Tag>
                }
            },
        },
        {
            title: 'Type',
            dataIndex: 'employment_type',
            key: 'employment_type',
            render: (_, record) => {
                if (record.employment_type?.id) {
                    return record.employment_type.name
                } else {
                    return 'null'
                }

            },
        },
        {
            title: 'View',
            key: 'view',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip placement="bottom" title={'Profile'}>
                        <FileFilled onClick={() => { navigate(`/employee/profile/${record.id}`) }} />
                    </Tooltip>
                    <Tooltip placement="bottom" title={'Time Schedule'}>
                        <ClockCircleOutlined onClick={() => { navigate(`/employee/detail/${record.id}`) }} />
                    </Tooltip>
                    <Tooltip placement="bottom" title={'Your Leave'}>
                        <SlidersFilled onClick={() => { navigate(`/employee/leave/${record.id}`) }} />
                    </Tooltip>
                </Space>
            ),
        },
        {
            title: 'Manage',
            key: 'manage',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip placement="bottom" title={'Edit Employee'}>
                        <EditFilled onClick={() => { navigate(`/employee/edit/${record.id}`) }} />
                    </Tooltip>
                    <Tooltip placement="bottom" title={'Education'}>
                        <BookOutlined onClick={() => { navigate(`/employee/education/${record.id}`) }} />
                    </Tooltip>
                    <Tooltip placement="bottom" title={'Weekday Setting'}>
                        <CalendarFilled onClick={() => {
                            setObjone({
                                worktimes: record.Day_worktime_employee_maps.sort((a, b) => a.dayworktimeId - b.dayworktimeId),
                                name: record.firstname_th,
                                id: record.id
                            });
                            setModalWorktimeOpen(true);
                        }} />
                    </Tooltip>
                </Space>
            ),
        },
    ];
    useEffect(() => {
        setIsloading(props.isLoading)
        return () => {
            // cleanup
        };
    }, [props]);
    useEffect(() => {
        for (let [key, value] of searchParams.entries()) {
            params[key] = value;
        }
        if (_.isEmpty(params.page)) {
            setSearchParams({ 'page': 1 })
            setCurrent(1)
        } else {
            setCurrent(parseInt(params.page))
        }
        return () => {
            // cleanup
        };
    }, [searchParams]);
    return (
        <>
            <Table loading={isLoading} columns={columns} dataSource={props.data}
                pagination={{
                    current: current, total: props.data.length,
                    onChange: (page, pageSize) => {
                        setCurrent(page);
                        setSearchParams({ 'page': page })
                    },
                }}
            />
            <Component_wroktime
                data={objOne}
                modalOpen={modalWorktimeOpen}
                onClose={() => {
                    setModalWorktimeOpen(false);
                }}
                handleUpdated={() => {
                    props.handleUpdated();
                }}
            />
        </>
    )
}
