import React, { useEffect, useState } from 'react'
import { Space, Table, Tooltip } from 'antd';
import { EditFilled, } from '@ant-design/icons'
import dayjs from 'dayjs';
import Component_leave from './editLeaveEmployee'
export default function Tableemployee(props) {
    const [modalWorktimeOpen, setModalWorktimeOpen] = useState(false);
    const [objOne, setObjone] = useState({});
    const [isLoading, setIsloading] = useState(true);
    const columns = [
        // {
        //     title: 'No.',
        //     dataIndex: 'key',
        //     key: 'key',
        // },
        {
            title: 'Leave Type',
            dataIndex: 'typeleaveId',
            key: 'typeleaveId',
            filters: [
                {
                    text: 'ลาป่วย',
                    value: 'ลาป่วย',
                },
                {
                    text: 'ลาทหาร',
                    value: 'ลาทหาร',
                },
                {
                    text: 'ลากิจ',
                    value: 'ลากิจ',
                },
                {
                    text: 'ลาพักร้อน',
                    value: 'ลาพักร้อน',
                },
                {
                    text: 'ลาศาสนา',
                    value: 'ลาศาสนา',
                },
                {
                    text: 'ลาขอทำงานที่บ้าน',
                    value: 'ลาขอทำงานที่บ้าน',
                },
                {
                    text: 'ลาอุบัติเหตุในงาน',
                    value: 'ลาอุบัติเหตุในงาน',
                },
                {
                    text: 'ลาไม่รับเงินเดือน',
                    value: 'ลาไม่รับเงินเดือน',
                },
                {
                    text: 'ลาทำหมัน',
                    value: 'ลาทำหมัน',
                },
                {
                    text: 'ลาคลอดบุตร',
                    value: 'ลาคลอดบุตร',
                },
            ],
            onFilter: (value, record) => record['typeleave'].name.indexOf(value) === 0,
            render: (_, record) => {
                return record['typeleave'].name
            },
        },
        {
            title: 'Year',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, record) => {
                return dayjs(_).format('YYYY')
            },
            sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        },
        {
            title: 'Used (day)',
            dataIndex: 'used',
            key: 'used',
            render(_, record) {
                return {
                    props: {
                        style: { background: record.used > record.max ? "rgba(33, 150, 243, 0.2)" : "" }
                    },
                    children: <div>{_ / 24}</div>
                };
            }
        },
        {
            title: 'Max (day)',
            dataIndex: 'max',
            key: 'max',
            render: (_, record) => {
                return (_ / 24)
            },
        },
        {
            title: 'Manage',
            key: 'view',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip placement="bottom" title={'Leave Day Setting'}>
                        <EditFilled onClick={() => {
                            setObjone({
                                leave: record,
                                name: record.typeleave.name,
                                year: dayjs(record.createdAt).format('YYYY'),
                                id: record.id
                            });
                            setModalWorktimeOpen(true);
                        }}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];
    useEffect(() => {
        setIsloading(props.isLoading)
        return () => {
            // cleanup
        };
    }, [props]);

    return (
        <>
            <Table loading={isLoading} columns={columns} dataSource={props.data} />
            <Component_leave
                data={objOne}
                modalOpen={modalWorktimeOpen}
                onClose={() => {
                    setModalWorktimeOpen(false);
                }}
                handleUpdated={() => {
                    props.handleUpdated();
                }}
            />
        </>
    )
}
