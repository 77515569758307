import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Input, message } from 'antd'
import { SearchOutlined, UploadOutlined } from '@ant-design/icons'
import { useParams, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

import TableLeave from '../../components/employee/tableLeaveEmployee';
import handlePrint from '../../components/employee/handlePrintforleave.js';
import getLevelEmployee from '../../api/employee/getLevelEmployee';

import durationTime from '../../utils/durations';

export default function LevelEmployee(props) {
    const [isFetch, setIsfetch] = useState(false);
    const [addButton, setAddbutton] = useState(false);
    const [isLoading, setIsloading] = useState(true);
    const [name, setName] = useState({
        title: '-'
    });
    const [datas, setDatas] = useState([]);
    const [datasFilter, setDatasFilter] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        if (!isFetch) {
            fetch_data(id);
        }
        return () => {
            // cleanup
        };
    }, []);
    const fetch_data = (id) => {
        getLevelEmployee(id, async (err, res) => {
            if (err) {
                console.log(err);
            }
            if (!err && res.statusCode === 200) {
                const age_y = await durationTime(dayjs(res.data.entry_date).format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD'),)
                res.data.Experience = `${age_y.years} years ${age_y.months} months ${age_y.days} days`
                setName(res.data)
                setDatas(res.data.employee_type_leave_maps);
                setDatasFilter(res.data.employee_type_leave_maps);
                setIsfetch(true);
                setIsloading(false);
            } else {
                message.error(res?.message ?? 'fail')
            }
        })
    }
    return (
        <div style={{ padding: '20px', margin: '20px 15px 15px 20px', background: '#FFF', borderRadius: '16px' }}>
            <Row align='middle' justify='space-between' style={{ marginBottom: '10px' }}>
                <Col span={10} style={{ color: "var(--ant-primary-color)" }}>
                    <Row style={{ color: "#2196F3", marginBottom: '10px', fontSize: '1.25rem' }}>{name.title.name}{name.firstname_th} {name.lastname_th}</Row>
                    <Row style={{ color: "#444", marginBottom: '10px', fontSize: '0.875rem' }}>Employee ID : {name.employeeId}</Row>
                    <Row style={{ color: "#444", marginBottom: '10px', fontSize: '0.875rem' }}>Entry Date : {dayjs(name.entry_date).format("DD/MM/YYYY")}</Row>
                    <Row style={{ color: "#444", marginBottom: '10px', fontSize: '0.875rem' }}>Years Experience : {name.Experience}</Row>
                </Col>
                <Col span={14}>
                    <Row style={{ flexWrap: 'nowrap', justifyContent: 'flex-end',marginBottom: '10px', gap: '8px', alignContent: 'end' }}>
                        <Button
                            type={'primary'}
                            onClick={() => navigate('/employee')}
                        >
                            Back
                        </Button>
                    </Row>
                    <Row style={{ flexWrap: 'nowrap', justifyContent: 'flex-end', gap: '8px', alignContent: 'end' }}>
                        {!addButton && (
                            <Input
                                suffix={
                                    <SearchOutlined style={{ color: 'rgba(79, 79, 79, 0.5)' }} />
                                }
                                className='ant-inputsearch search-border'
                                placeholder={`Search....`}
                                style={{
                                    margin: '0px 0px 0px 10px',
                                    borderRadius: '8px',
                                    maxWidth: '200px'
                                }}
                                onChange={e => {
                                    const currValue = e.target.value
                                    const filteredData = datasFilter.filter(entry =>
                                        Object.keys(entry).filter((k) => k).some(s => {
                                            return String(entry[s])
                                                .toLowerCase()
                                                .includes(currValue.toLowerCase())
                                        })
                                    )
                                    setDatas(filteredData)
                                }}
                            />
                        )}
                        <Button
                            loading={!isFetch}
                            style={{
                                borderRadius: '8px',
                                color: '#2196F3',
                                border: '1px solid ',
                                fontWeight: '500',
                            }}
                            ghost
                            onClick={() => { handlePrint(datas); }}
                        >
                            <UploadOutlined /> Export
                        </Button>
                    </Row>
                </Col>
            </Row>

            {isFetch && (
                <TableLeave
                    data={datas} isLoading={isLoading}
                    handleUpdated={() => {
                        setIsloading(true);
                        setTimeout(() => {
                            fetch_data(id);
                        }, 1000);
                    }}
                />
            )}
        </div>
    )
}
