import React, { useState, useEffect, } from 'react'
export default function Gear(props) {
    const [activedateMenu, setActivedateMenu] = useState(props.activedateMenu)
    useEffect(() => {
        setActivedateMenu(props.activedateMenu)
    }, [props])
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Gear">
                <path id="Vector" d="M8.93955 1.96582L8.95755 2.02567C9.16267 1.96399 9.37893 1.94869 9.59069 1.98087C9.80244 2.01304 
                10.0044 2.09189 10.1819 2.21172L10.1822 2.21192L11.4427 3.052L11.4585 3.0625H11.4774H12.5233H12.5422L12.558 3.052L13.8177 
                2.21191L13.8179 2.21178C13.9959 2.09215 14.1981 2.01342 14.4101 1.98124C14.622 1.94908 14.8384 1.96424 
                15.0438 2.02565C15.6863 2.22121 16.3085 2.4784 16.9015 2.79364C17.0902 2.89512 17.2539 3.03725 17.3809 
                3.20974C17.5079 3.38228 17.5949 3.58089 17.6358 3.79121L17.6358 3.79155L17.9332 5.27837L17.9369 5.29692L17.9503 
                5.3103L18.69 6.05005L18.7034 6.06343L18.722 6.06714L20.2088 6.36451L20.2091 6.36457C20.4191 6.40544 20.6175 
                6.49238 20.7899 6.61913C20.9622 6.74581 21.1043 6.90912 21.2059 7.0973C21.5218 7.69086 21.7793 8.31372 
                21.9746 8.95709C22.0351 9.16267 22.0497 9.37901 22.0174 9.59084C21.9851 9.80276 21.9067 10.005 21.7877 
                10.1833L21.7877 10.1833L20.9483 11.4424L20.9378 11.4581V11.477V12.5229V12.5419L20.9483 12.5576L21.7884 
                13.8181C21.9072 13.9964 21.9854 14.1985 22.0176 14.4103C22.0498 14.622 22.0351 14.8383 21.9746 15.0437C21.7793 
                15.6862 21.5221 16.3083 21.2067 16.9012C21.1052 17.0896 20.9631 17.2532 20.7908 17.3802C20.6184 17.5072 20.4199 
                17.5944 20.2098 17.6354L20.2095 17.6355L18.722 17.9328L18.7034 17.9366L18.6901 17.9499L17.9496 18.6897L17.9362 
                18.7031L17.9325 18.7216L17.6351 20.2092L17.635 20.2097C17.5948 20.4201 17.5079 20.6188 17.3809 20.7912C17.2539 
                20.9637 17.0898 21.1055 16.9009 21.2063C16.3081 21.5218 15.6861 21.779 15.0437 21.9744L15.0436 21.9744C14.909 
                22.0156 14.7691 22.0366 14.6284 22.0369C14.3396 22.0358 14.0576 21.9493 13.8179 21.7882L13.8177 21.7881L12.558 
                20.948L12.5422 20.9375H12.5233H11.4774H11.4585L11.4427 20.948L10.1822 21.7881L10.1817 21.7884C10.0044 21.9091 
                9.80225 21.9885 9.59016 22.0205C9.37807 22.0526 9.16149 22.0366 8.95642 21.9737L8.9562 21.9736C8.31318 21.7789 
                7.69069 21.5219 7.09758 21.2063L7.06822 21.2615M8.93955 1.96582L8.95771 2.02562C8.31426 2.221 7.69133 2.47846 
                7.0977 2.79437C6.90922 2.89566 6.74568 3.03765 6.61893 3.21004C6.49217 3.38247 6.4054 3.58096 6.36494 3.79111L6.36486 
                3.79155L6.06749 5.27837L6.06378 5.29692L6.0504 5.3103L5.31065 6.05005L5.29727 6.06344L5.2787 6.06714L3.79042 
                6.36451L3.79007 6.36458C3.58001 6.4053 3.38164 6.49225 3.20935 6.61913C3.03708 6.746 2.8952 6.9096 2.79398 
                7.09808C2.47846 7.69136 2.22102 8.31375 2.02526 8.95656C1.9637 9.16179 1.94846 9.37811 1.98063 9.58994C2.01281 
                9.80181 2.09161 10.0039 2.21136 10.1816L2.21152 10.1819L3.05234 11.4424L3.06285 11.4581V11.477V12.5229V12.5419L3.05234 
                12.5576L2.21078 13.8189L2.21066 13.8191C2.09115 13.9968 2.01254 14.1988 1.98049 14.4105C1.94844 14.6222 1.96373 14.8384 
                2.02528 15.0435C2.22078 15.6859 2.47823 16.3078 2.79399 16.9005C2.89498 17.0894 3.03687 17.2534 3.20932 17.3806C3.38176 
                17.5077 3.5804 17.5948 3.79076 17.6354L3.79116 17.6355L5.27871 17.9328L5.29727 17.9366L5.31065 17.9499L6.0504 
                18.6897L6.06378 18.7031L6.06749 18.7216L6.36485 20.2077L6.36497 20.2083C6.40498 20.4188 6.49155 20.6176 6.61834 
                20.7903C6.74512 20.963 6.90892 21.1052 7.09774 21.2064L7.06822 21.2615M8.93955 1.96582C8.29219 2.16238 
                7.66546 2.42141 7.06822 2.73925C6.87152 2.84494 6.70084 2.99312 6.56858 3.17302C6.43631 3.35292 6.34578 
                3.56003 6.30357 3.77929L6.00621 5.26611L5.26646 6.00586L3.77818 6.30322L16.931 2.73852C16.3343 2.42134 
                15.7084 2.16257 15.0619 1.96582C14.8476 1.90173 14.6218 1.88589 14.4007 1.91945C14.1796 1.95301 13.9687 
                2.03513 13.7831 2.15991L12.5233 3H11.4774L10.2169 2.15991C10.0317 2.03491 9.82099 1.95264 9.60007 1.91908C9.37916 
                1.88551 9.15354 1.90147 8.93955 1.96582ZM7.06822 21.2615C7.66492 21.579 8.29117 21.8375 8.93809 22.0334M7.06822 
                21.2615C6.87118 21.1559 6.70026 21.0075 6.56796 20.8273C6.43565 20.6471 6.34532 20.4396 6.30357 20.22L6.00621 
                18.7339L5.26646 17.9941L3.77891 17.6968C3.55942 17.6544 3.35215 17.5636 3.17222 17.4309C2.9923 17.2982 2.84425 
                17.1271 2.73887 16.9299C2.42116 16.3336 2.16213 15.7079 1.96543 15.0615L8.93809 22.0334M8.93809 22.0334C9.15211 
                22.0991 9.37815 22.1158 9.5995 22.0823C9.82086 22.0489 10.0319 21.9661 10.2169 21.8401L8.93809 22.0334ZM18.7342 
                17.9941L17.9938 18.7339L17.6964 20.2214C17.6544 20.441 17.5638 20.6483 17.4312 20.8283C17.2987 21.0083 17.1275 
                21.1563 16.9303 21.2615L18.7342 17.9941ZM18.7342 17.9941L20.2218 17.6968L18.7342 17.9941ZM3.00035 12.5229L2.15879 
                13.7842L2.73887 7.0686C2.42141 7.66551 2.16239 8.29172 1.96543 8.93847C1.90119 9.15259 1.88528 9.37831 1.91884 
                9.59932C1.9524 9.82034 2.03461 10.0312 2.15953 10.2165L3.00035 11.477V12.5229ZM17.9945 5.26611L17.6971 3.77929L22.0345 
                8.93921C21.838 8.29184 21.5789 7.66511 21.2611 7.06787C21.155 6.87145 21.0068 6.70099 20.8269 6.56877C20.6471 6.43655 
                20.4402 6.34586 20.221 6.30322L18.7342 6.00586L17.9945 5.26611ZM9.46528 15.7937C10.2156 16.295 11.0977 16.5626 
                12.0001 16.5626C13.2101 16.5626 14.3706 16.0819 15.2262 15.2263C16.0819 14.3706 16.5626 13.2101 16.5626 
                12.0001C16.5626 11.0977 16.295 10.2156 15.7937 9.4653C15.2923 8.715 14.5798 8.13022 13.7461 7.78489C12.9124 
                7.43957 11.995 7.34921 11.11 7.52526C10.2249 7.7013 9.41198 8.13584 8.7739 8.77392C8.13582 9.41199 7.70129 
                10.225 7.52524 11.11C7.3492 11.995 7.43955 12.9124 7.78487 13.7461C8.1302 14.5798 8.71498 15.2923 9.46528 15.7937Z" 
                fill={(activedateMenu === 'grouppermission' || activedateMenu === 'holiday' || activedateMenu === 'broadcast') ? "#2196F3" : "#444444"} stroke="#444444" stroke-width="0.125" />
            </g>
        </svg>
    )
}