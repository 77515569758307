import React from 'react'
import { Col, Row, Divider } from 'antd';
import dayjs from 'dayjs'
export default function EducationEmployee(props) {
    return (
        <div style={styles.box}>
            <Divider orientation="left" style={{ margin: 0 }}>
                Education
            </Divider>
            <div style={{ color: 'var(--818283, #818283)', strokeWidth: '1px', stroke: 'var(--2196-f-3, #2196F3)' }}>Education</div>
            {
                props.datas.education_employee_maps
                    .sort(function (a, b) {
                        return new Date(b.congratulationsDate) - new Date(a.congratulationsDate);
                    })
                    .map((item, index) => (
                        <Row>
                            <Col span={6}>
                                <p style={styles.leftTitleTop}>{item.congratulationsDate !== null ? dayjs(item.congratulationsDate).format('MMM') : '-'}</p>
                                <span style={styles.leftTitleBottom}>{item.congratulationsDate !== null ? dayjs(item.congratulationsDate).format('YYYY') : '-'}</span>
                            </Col>
                            <Col span={18}>
                                <p>{item.education.name}</p>
                                <p style={styles.rightBrean}>{item.branch}</p>
                                <p>{item.institution}</p>
                                <p>{item.score}</p>
                            </Col>
                        </Row>
                    ))
            }
        </div >
    )
}
const styles = {
    box: {
        borderRadius: '1rem',
        border: '1px solid rgba(129, 130, 131, 0.10)',
        background: '#FFF',
        boxShadow: '1px 3px 4px 0px rgba(129, 130, 131, 0.15)',
        padding: '20px', margin: '0 15px 0px 20px',
        display: 'flex',
        padding: '1.5rem 1.5rem 1rem 1rem',
        flexDirection: 'column',
        alignItems: 'flexStart',
        gap: '1rem',
        // height: '100%',
        overflowY: 'auto',
        minHeight: '100%',
        maxHeight: '452px',
    },
    leftTitleBottom: {
        color: 'var(--333333, #333)',
        // textAlign: 'right',
        /* Body 18 semi b */
        fontSize: '1.125rem',
        fontWeight: '500',
    },
    leftTitleTop: {
        color: 'var(--444444, #444)',
        /* Body 16 regular */
        fontSize: '1rem',
        fontWeight: '400',
    },
    rightEduLevel: {},
    rightBrean: {
        color: 'rgba(33, 150, 243, 0.75)',
        fontSize: '1rem',
        fontWeight: 500
    },
}