import React, { useEffect, useState } from "react";
import { Row, Col, Progress, Spin } from "antd";

import anime from "animejs";
export default function Box_event(props) {
  const [objOne, setObjone] = useState({});
  const [label, setLabel] = useState("");
  const [color, setColor] = useState("");
  const [isLoading, setIsloading] = useState(true);
  useEffect(() => {
    if (props.label.includes("work")) {
      if (props.label.includes("in")) {
        setLabel("Worktime Check in");
      } else {
        setLabel("Worktime Check out");
      }
      setColor("#99CECB");
    } else if (props.label.includes("over")) {
      if (props.label.includes("in")) {
        setLabel("Overtime Check in");
      } else {
        setLabel("Overtime Check out");
      }
      setColor("#F39993");
    } else if (props.label.includes("leave")) {
      setLabel("Leave");
      setColor("#FEB446");
    } else {
      setLabel("No Action");
      setColor("#BBBDC0");
    }
    setObjone(props.data);
    setIsloading(props.isLoading);
    anime({
      targets: ".el",
      translateY: [-50, 0],
      duration: 1500,
      easing: "easeInOutSine",
      opacity: [0, 1],
    });
    return () => {
      // cleanup
    };
  }, [props]);

  return (
    <Spin spinning={isLoading}>
      <div
        style={Object.assign({ background: color }, style.box)}
        className="el"
      >
        <Row justify="center">
          <Col span={18}>
            <div style={style.title_box}>{label}</div>
            {label !== "No Action" && (
              <>
                {Object.keys(objOne)
                  .slice(0, 3)
                  .map((d, i) => (
                    <Row
                      align="middle"
                      justify="space-between"
                      style={style.text_others}
                    >
                      <Col span={15}>
                        <div style={{ textTransform: "capitalize" }}>{d}</div>
                      </Col>
                      <Col span={9}>
                        <div>{objOne[d]}</div>
                      </Col>
                    </Row>
                  ))}
              </>
            )}
          </Col>
          <Col span={6}>
            <Row justify="end">
              <div>
                <div style={style.total_box}>{objOne.person}</div>
                <div style={style.text_person}>person</div>
              </div>
            </Row>
          </Col>
        </Row>
        {label.includes("Worktime") && (
          <Row justify="center" style={{ padding: "16px 9px 0 9px" }}>
            <Progress
              percent={(100 - ((objOne.max - objOne.person) / objOne.max) * 100)}
              size={["100%"]}
              strokeColor={"#FFFFFF"}
              showInfo={false}
            />
          </Row>
        )}
      </div>
    </Spin>
  );
}

const style = {
  box: {
    // width: '20.625rem',
    // height:'100px',
    borderRadius: "1rem",
    padding: "16px",
  },
  title_box: {
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "21px",
    letterSpacing: "0em",
    color: "#FFFFFF",
    marginBottom: "12px",
  },
  text_others: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16px",
    letterSpacing: "0em",
    color: "#FFFFFF",
    padding: "4px 0px",
  },
  total_box: {
    width: "48px",
    height: "47px",
    padding: "12px",
    borderRadius: "8px",
    gap: "10px",
    background: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "23px",
    letterSpacing: "0em",
    color: "#333333",
  },
  text_person: {
    fontSize: "10px",
    fontWeight: "400",
    lineHeight: "12px",
    textAlign: "center",
    padding: "4px 0px",
    color: "#333333",
  },
};
