import React, { useEffect, useState } from "react";
import { Button, Row, Modal, notification, Input, Alert } from "antd";

import deleteLeave from "../../api/leave/deleteLeave";

export default function ModaldeleteHoliday(prop) {
    const [modalOpen, setModalOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState("");
    const [id, setId] = useState("");
    const [api, contextHolder] = notification.useNotification();
    useEffect(() => {
        setModalOpen(prop.modalOpen);
        setId(prop.data.id);
        setData("");
        setIsError(false);
    }, [prop]);
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };
    return (
        <>
            <Modal
                title={`Delete Leave Request`}
                open={modalOpen}
                footer={null}
                onCancel={() => {
                    prop.onClose();
                }}
                closable={true}
            >
                {contextHolder}
                <div
                    style={{
                        fontSize: "16px",
                        color: "#82828",
                        fontWeight: "normal",
                        textAlign: 'center',
                        color: '#4F4F4F'
                    }}
                >
                    You’re going to delete this leave request. Are you sure?
                    If sure, please enter a reason.
                </div>
                <div style={{ margin: '20px' }}>
                    {isError && <Alert message="Pls enter Char" type="warning" />}

                    <Input.TextArea value={data} onChange={(e) => { setData(e.target.value) }} maxLength={50} showCount />
                </div>


                <Row style={{ justifyContent: "center", }}>
                    <Button
                        htmlType="button"
                        style={{
                            boxShadow: "unset",
                            textShadow: "unset",
                            borderRadius: "8px",
                            fontSize: "14px",
                            margin: "0 0 0px 10px",
                            fontWeight: "500",
                        }}
                        onClick={() => {
                            prop.onClose();
                        }}
                    >
                        No, Deep It.
                    </Button>
                    <Button
                        type="primary"
                        htmlType="button"
                        style={{
                            boxShadow: "unset",
                            textShadow: "unset",
                            borderRadius: "8px",
                            fontSize: "14px",
                            margin: "0 0 0px 10px",
                            fontWeight: "500",
                        }}
                        danger
                        onClick={() => {
                            if (data === '') {
                                setIsError(true);
                                return;
                            }
                            deleteLeave(id, { 'detail': data }, (err, res) => {
                                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                                    prop.handleUpdated();
                                    prop.onClose();

                                } else {
                                    openNotificationWithIcon('error', 400, err ?? 'error not known');
                                }
                            });
                        }}
                    >
                        Yes, Delete!
                    </Button>
                </Row>
            </Modal>
        </>
    );
}
