import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs';
export const overviewSlice = createSlice({
  name: 'overview',
  initialState: { dateOfweek: dayjs().format('YYYY-MM-DD'),},
  reducers: {
    editdateOfweek: (state, action) => {
      let tmp = action.payload
      state.dateOfweek = String(tmp)
    },

  }
})

export const { editdateOfweek, } = overviewSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getOverView = state => state.overView.dateOfweek

export default overviewSlice.reducer
