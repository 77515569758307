import React, { useEffect, useState } from "react";
import {
    Form,
    Input,
    Button,
    Row,
    Col,
    Modal,
    message,
    notification,
    Radio, DatePicker, Space
} from "antd";
import dayjs from 'dayjs';
import _ from 'lodash';

import editOvertime from "../../api/overtime/editOvertime";



export default function ModaleditOvertime(prop) {
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [editData, setEditData] = useState({});
    const [name, setName] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    useEffect(() => {
        if (!_.isEmpty(prop.data)) {
            setModalEditOpen(prop.modalOpen);
            setEditData(prop.data);
            setName(prop.data.name);
            form.setFieldsValue({
                startDate: dayjs(`${prop.data.sortStartDate} ${prop.data.checkin}`),
                endDate: dayjs(`${prop.data.sortEndDate} ${prop.data.checkout}`),
                total: prop.data.sumtotal,
                status_hr: 1
            });
        }

    }, [prop]);
    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };
    return (
        <>
            <Modal
                title={<div style={{ color: '#2196F3' }}>{`Edit ${name}`}</div>}
                open={modalEditOpen}
                onCancel={() => {
                    setModalEditOpen(false);
                    form.resetFields();

                    prop.onClose();
                }}
                footer={null}
                closable={true}
                width={500}
            >
                {contextHolder}
                <Form
                    autoComplete="off"
                    layout="vertical"
                    size="large"
                    form={form}
                    style={{ padding: '10px' }}
                    onFinish={(e) => {
                        editOvertime(
                            editData.id,
                            e,
                            (err, res) => {
                                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                                    prop.handleUpdated();
                                    setModalEditOpen(false);
                                    prop.onClose();
                                    message.success("Edit success");
                                    form.resetFields();
                                } else {
                                    openNotificationWithIcon('error', 400, err ?? 'error not known');
                                }
                            }
                        );
                    }}
                >
                    <Row justify={'center'}>
                        <Space>
                            <Col >
                                <Form.Item
                                    label='Start Date'
                                    name={`startDate`}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Input something!',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        showTime={{
                                            format: 'HH:mm',
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col >
                                <Form.Item
                                    label='End Date'
                                    name={`endDate`}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Input something!',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        showTime={{
                                            format: 'HH:mm',
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Space>
                    </Row>
                    <Row style={{ alignItems: "center" }} >
                        {/* <Space> */}
                        <Col span={12}>
                            <Form.Item
                                label="Status"
                                name={`status_hr`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Input something!',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value={1}>Appove</Radio>
                                    <Radio value={2}>Disappove</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Total'
                                name={`total`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Input something!',
                                    },
                                ]}
                            >
                                <Input.TextArea
                                    showCount maxLength={20}
                                    placeholder="Total"
                                    autoSize={{
                                        minRows: 1,
                                        maxRows: 5,
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        {/* </Space> */}
                    </Row>
                    <Row style={{ alignItems: "center" }} >
                        <Col span={24}>
                            <Form.Item
                                label='Note'
                                name={`note_hr`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Input something!',
                                    },
                                ]}
                            >
                                <Input.TextArea
                                    showCount maxLength={255}
                                    placeholder="Note"
                                    autoSize={{
                                        minRows: 1,
                                        maxRows: 5,
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                    </Row>
                    <Row justify="center" style={{ padding: "0 25.57px", gap: "8px" }}>
                        <Button
                            htmlType="button"
                            style={{
                                boxShadow: "unset",
                                textShadow: "unset",
                                borderRadius: "8px",
                                fontSize: "14px",
                                fontWeight: "500",
                            }}
                            onClick={() => {
                                form.resetFields();
                                prop.onClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            htmlType="submit"
                            type="primary"
                            style={{
                                boxShadow: "unset",
                                textShadow: "unset",
                                borderRadius: "8px",
                                fontSize: "14px",
                                fontWeight: "500",
                            }}
                        >
                            Edit Overtime
                        </Button>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}
