const DisconnectNode = (formdata, cb) => {
    let token = sessionStorage.getItem('token')
    token = JSON.parse(token)
    fetch(`${process.env.REACT_APP_API_URL_HELLOTEAM}api/v2/group/deletepestent`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formdata)
    })
      .then(res => res.json())
      .then(json => cb(null, json))
      .catch(err => cb(err, null))
  }
  
  export default DisconnectNode
  