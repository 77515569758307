import React, { useState, useEffect, } from 'react'
export default function Users(props) {
    const [activedateMenu, setActivedateMenu] = useState(props.activedateMenu)
    useEffect(() => {
        setActivedateMenu(props.activedateMenu)
    }, [props])
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Users">
                <path id="Vector" d="M8.25006 15C10.9424 15 13.1251 12.8174 13.1251 10.125C13.1251 7.43261 10.9424 5.25 8.25006 5.25C5.55767 5.25 3.37506 7.43261 3.37506 10.125C3.37506 12.8174 5.55767 15 8.25006 15Z" stroke={activedateMenu === 'employee' ? "#2196F3" : "#818283"} stroke-width="2" stroke-miterlimit="10" />
                <path id="Vector_2" d="M14.5699 5.43158C15.2405 5.24266 15.9437 5.19962 16.6322 5.30537C17.3208 5.41111 17.9787 5.66318 18.5616 6.04459C19.1445 6.42601 19.639 6.92791 20.0116 7.5165C20.3842 8.10509 20.6264 8.7667 20.7218 9.45676C20.8172 10.1468 20.7636 10.8493 20.5646 11.5169C20.3657 12.1845 20.026 12.8018 19.5684 13.327C19.1108 13.8523 18.5459 14.2734 17.9119 14.562C17.2779 14.8505 16.5893 14.9999 15.8927 15" stroke={activedateMenu === 'employee' ? "#2196F3" : "#818283"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path id="Vector_3" d="M1.49963 18.5059C2.26101 17.4229 3.27178 16.539 4.44662 15.9288C5.62145 15.3186 6.92586 15.0001 8.24971 15C9.57356 14.9999 10.878 15.3184 12.0529 15.9285C13.2278 16.5386 14.2386 17.4225 15.0001 18.5054" stroke={activedateMenu === 'employee' ? "#2196F3" : "#818283"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path id="Vector_4" d="M15.8927 15C17.2167 14.999 18.5214 15.3171 19.6964 15.9273C20.8713 16.5375 21.882 17.4218 22.6427 18.5054" stroke={activedateMenu === 'employee' ? "#2196F3" : "#818283"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
        </svg>

    )
}
