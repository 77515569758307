import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Modal,
  message,
  notification
} from "antd";

import editEducation from "../../api/education/editEducation";


export default function ModaleditEducation(prop) {
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [name, setName] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  useEffect(() => {
    setEditData(prop.data);
    setName(prop.data.name);
    setModalEditOpen(prop.modalOpen);
    form.setFieldsValue({ name: prop.data.name })
  }, [prop]);
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };
  return (
    <>
      <Modal
        title={`Edit ${name}`}
        open={modalEditOpen}
        onCancel={() => {
          setModalEditOpen(false);
          form.resetFields();

          prop.onClose();
        }}
        footer={null}
        closable={true}
        width={500}
      >
        {contextHolder}
        <Form
          autoComplete="off"
          layout="vertical"
          size="large"
          form={form}
          onFinish={(e) => {
            editEducation(
              editData.id,
              {
                name: e.name,
              },
              (err, res) => {
                if (!err && res.statusCode >= 200 && res.statusCode < 300) {
                  prop.handleUpdated();
                  setModalEditOpen(false);
                  prop.onClose();
                  message.success("Edit success");
                  form.resetFields();
                } else {
                  openNotificationWithIcon('error', 400, err ?? 'error not known');
                }
              }
            );
          }}
        >
          <Row style={{ alignItems: "start" }}>
          <Col span={24} style={{ margin: "0 25px 0 0" }}>
              <Form.Item
                label={`Education level`}
                autoComplete="off"
                name="name"
                rules={[
                  {
                    required: true,
                    message: ``
                  },
                ]}
              >
                <Input
                  showCount
                  className="inputbox"
                  placeholder={`Education level`}
                  style={{ borderRadius: "8px" }}
                  maxLength={30}
                />
              </Form.Item>
            </Col>


          </Row>
          <Row justify="end" style={{ padding: "0 25.57px", gap: "8px" }}>
            <Button
              htmlType="button"
              style={{
                boxShadow: "unset",
                textShadow: "unset",
                borderRadius: "8px",
                fontSize: "14px",
                fontWeight: "500",
              }}
              onClick={() => {
                form.resetFields();
                prop.onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              style={{
                boxShadow: "unset",
                textShadow: "unset",
                borderRadius: "8px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Edit Education
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
