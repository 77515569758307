import React, { useEffect, useState, useRef } from "react";
import getEmployee from "../../../api/employee/getOne";
import {
  Row,
  Col,
  message,
  Spin,
  Card,
  Divider,
} from "antd";
import { useParams } from "react-router-dom";
import { Typography } from "antd";
import dayjs from "dayjs";
import durationTime from "../../../utils/durations";
import _ from 'lodash';
const { Title } = Typography;
export default function ResumeExport() {
  const [isFetch, setIsfetch] = useState(false);
  const [ageExp, setAgeexp] = useState('');
  const [employeeDetail, setEmployeedetail] = useState({
    employeeId: "-",
    firstname_th: "-",
    lastname_th: "-",
    nickname: "-",
    positions: "-",
    bu: "-",
    education_employee_maps: [],
  });
  const ref_print = useRef(null);
  const { id } = useParams();
  useEffect(() => {
    if (!isFetch) {
      fetchData(id);
    }

    return () => {
      // cleanup
    };
  }, []);
  const fetchData = (id) => {
    getEmployee(id, async (err, res) => {
      if (err) {
        console.log(err);
      }
      if (!err && res.statusCode === 200) {
        let employee = res.data;
        employee["titleId"] = employee.title?.id ?? "-";
        employee["title"] = employee.title?.name ?? "-";
        employee["bu"] = employee.positions?.Sections?.Departments?.business_groups?.name ?? "-";
        employee["department"] = employee.positions?.Sections?.Departments?.name ?? "-";
        employee["section"] = employee.positions?.Sections?.name ?? "-";
        employee["positions"] = employee.positions?.name ?? "-";
        var age_y = await durationTime(dayjs(employee.birthday_date).format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD'),)
        if (age_y.years) {
          employee['age'] = `${age_y.years} years ${age_y.months} months ${age_y.days} days`
        } else {
          employee['age'] = '-'
        }
        var age_e = await durationTime(dayjs(employee.entry_date).format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD'),)
        if (age_e.asDays) {
          setAgeexp(age_e)
        }
        setEmployeedetail(employee);
        setIsfetch(true);
        setTimeout(() => {
          window.print()
        }, 300);
      } else {
        message.error(res?.message ?? "fail");
      }
    });
  };
  return (
    <Spin spinning={!isFetch}>
      {isFetch && (
        <Row justify={"center"}>
          <Card
            style={{
              width: 800,
              border: "0",
            }}
          >
            <Row style={{ alignItems: "center" }}>
              <Col span={16}>
                <Row>
                  <Title
                    style={{
                      margin: "0 5px 0 0",
                      color: "#444",
                      fontFamily: "Sarabun, sans-serif",
                    }}
                  >
                    {employeeDetail.firstname_en}
                  </Title>

                  <Title
                    style={{
                      margin: "0 0px 0 5px",
                      color: "#444",
                      fontFamily: "Sarabun, sans-serif",
                    }}
                  >
                    {employeeDetail.lastname_en}
                  </Title>
                </Row>
              </Col>

              <Col span={8} style={{ textAlign: "end" }}>
                <div style={{ textAlign: "-webkit-right" }}>
                  <Card
                    style={{
                      width: 113.385827,
                      height: 151.181102,
                      border: "1px solid #d7d7d7",
                      borderRadius: "0",
                    }}
                  />
                </div>
              </Col>
            </Row>
            <div>
              <Title
                level={4}
                style={{
                  margin: "0 5px 0 0",
                  textTransform: "uppercase",
                  color: "#2196F3",
                  fontFamily: "Sarabun, sans-serif",
                }}
              >
                Employee Profile
              </Title>
              <Divider style={{ margin: "4px 0px 16px 0px", border: '1px solid rgba(33,150,243,0.5)' }} />
              <Row>
                <Col span={12}>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Gender : </span>
                    <span style={styles.text_normal}>
                      {employeeDetail.gender.name}
                    </span>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Religion : </span>
                    <span style={styles.text_normal}>
                      {employeeDetail.personal.religion}
                    </span>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Nationality : </span>
                    <span style={styles.text_normal}>
                      {employeeDetail.personal.nationality}
                    </span>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Identification Card : </span>
                    <span style={styles.text_normal}>
                      {employeeDetail.personal.number}
                    </span>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Issue Date : </span>
                    <span style={styles.text_normal}>
                      {!_.isEmpty(employeeDetail.personal.issue_card) ? dayjs(employeeDetail.personal.issue_card).format("DD/MM/YYYY") : '-'}
                    </span>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Expired Date : </span>
                    <span style={styles.text_normal}>
                      {!_.isEmpty(employeeDetail.personal.expire_date) ? dayjs(employeeDetail.personal.expire_date).format("DD/MM/YYYY") : '-'}
                    </span>
                  </div>
                </Col>
                <Col span={12}>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Age : </span>
                    <span style={styles.text_normal}>
                      {employeeDetail.age}
                    </span>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Birthday : </span>
                    <sapn style={styles.text_normal}>
                      {!_.isEmpty(employeeDetail.birthday_date) ? dayjs(employeeDetail.birthday_date).format("DD/MM/YYYY") : '-'}
                    </sapn>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Home Address : </span>
                    <div style={styles.text_normal}>
                      {!_.isEmpty(employeeDetail.address) ? (
                        <>
                          {employeeDetail.address.address_detail} &nbsp;
                          {employeeDetail.address.road} &nbsp;
                          {employeeDetail.address.soi} &nbsp;
                          {employeeDetail.address.subdistrict} &nbsp;
                          {employeeDetail.address.district} &nbsp;
                          {employeeDetail.address.province} &nbsp;
                          {employeeDetail.address.postal_code}
                        </>
                      ) : '-'}
                    </div>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Mailing Address : </span>
                    <div style={styles.text_normal}>
                      {!_.isEmpty(employeeDetail.personal) ? (
                        <>
                          {employeeDetail.personal.address_detail} &nbsp;
                          {employeeDetail.personal.road} &nbsp;
                          {employeeDetail.personal.soi} &nbsp;
                          {employeeDetail.personal.subdistrict} &nbsp;
                          {employeeDetail.personal.district} &nbsp;
                          {employeeDetail.personal.province} &nbsp;
                          {employeeDetail.personal.postal_code}
                        </>
                      ) : '-'}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <Title
                level={4}
                style={{
                  margin: "10px 5px 0 0",
                  textTransform: "uppercase",
                  color: "#2196F3",
                  fontFamily: "Sarabun, sans-serif",
                }}
              >
                Employee Detail
              </Title>
              <Divider style={{ margin: "4px 0px 16px 0px", border: '1px solid rgba(33,150,243,0.5)' }} />
              <Row>
                <Col span={12}>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Phone Number : </span>
                    <span style={styles.text_normal}>
                      {employeeDetail.phone !== '-' ? employeeDetail.phone.substr(0, 3) + '-' + employeeDetail.phone.substr(3, 3) + '-' + employeeDetail.phone.substr(6, 4) : '-'}
                    </span>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Email : </span>
                    <span style={styles.text_normal}>
                      {employeeDetail.email}
                    </span>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Year's Experience : </span>
                    <span style={styles.text_normal}>
                      {ageExp !== '' ? `${ageExp.years} years ${ageExp.months} months ${ageExp.days} days` : '-'}
                    </span>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Entry Date : </span>
                    <span style={styles.text_normal}>
                      {!_.isEmpty(employeeDetail.entry_date) ? dayjs(employeeDetail.entry_date).format("DD/MM/YYYY") : '-'}
                    </span>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Probation Date : </span>
                    <span style={styles.text_normal}>
                      {!_.isEmpty(employeeDetail.entry_date) ? dayjs(employeeDetail.entry_date).add(119, 'day').format("DD/MM/YYYY") : '-'}
                    </span>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Tier Level : </span>
                    <span style={styles.text_normal}>
                      {employeeDetail.tier_level}
                    </span>
                  </div>
                </Col>
                <Col span={12}>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Employee Type : </span>
                    <span style={styles.text_normal}>
                      {employeeDetail.employment_type.name}
                    </span>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Position : </span>
                    <span style={styles.text_normal}>
                      {employeeDetail.positions}
                    </span>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Section : </span>
                    <span style={styles.text_normal}>
                      {employeeDetail.section}
                    </span>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Department : </span>
                    <span style={styles.text_normal}>
                      {employeeDetail.department}
                    </span>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Group Position : </span>
                    <span style={styles.text_normal}>
                      {employeeDetail.group_positions?.name}
                    </span>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <span style={styles.text_color}>Business Unit : </span>
                    <span style={styles.text_normal}>
                      {employeeDetail.bu}
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
            {employeeDetail.education_employee_maps.length > 0 && (
              <div>
                <Title
                  level={4}
                  style={{
                    margin: "10px 5px 0 0",
                    textTransform: "uppercase",
                    color: "#2196F3",
                    fontFamily: "Sarabun, sans-serif",
                  }}
                >
                  Education
                </Title>
                <Divider style={{ margin: "4px 0px 16px 0px", border: '1px solid rgba(33,150,243,0.5)' }} />
                <Row gutter={[16, 16]}>
                  {employeeDetail.education_employee_maps
                    .sort(function (a, b) {
                      return (new Date(b.congratulationsDate) - new Date(a.congratulationsDate)
                      );
                    })
                    .map((item, index) => {
                      return (
                        <Col span={12}>
                          <Row>
                            <Col span={5}>
                              <div style={styles.text_edu}>
                                {item.congratulationsDate !== null ? dayjs(item.congratulationsDate).format("MMM") : "-"}
                              </div>
                              <div style={styles.text_edu_year}>
                                {item.congratulationsDate !== null ? dayjs(item.congratulationsDate).format("YYYY") : "-"}
                              </div>
                            </Col>
                            <Col span={19}>
                              <div style={styles.text_edu}>{item.education.name}</div>
                              <div style={styles.text_color}>{item.branch}</div>
                              <div style={styles.text_edu}>{item.institution}</div>
                              <div style={styles.text_edu}>{item.score}</div>
                            </Col>
                          </Row>
                        </Col>
                      )
                    }
                    )}
                </Row>
              </div>
            )}
          </Card>
        </Row>
      )}
    </Spin>
  );
}
const styles = {
  text_normal: {
    color: "#444444",
    fontWeight: "400",
    fontSize: "0.875rem",
    fontFamily: "Sarabun, sans-serif",
  },
  text_edu: {
    fontWeight: "400",
    color: "#444444",
    fontSize: "0.875rem",
    fontFamily: "Sarabun, sans-serif",
  },
  text_color: {
    fontWeight: "500",
    color: "rgba(33, 150, 243, 0.75)",
    fontSize: "0.875rem",
    textTransform: "capitalize",
    fontFamily: "Sarabun, sans-serif",
  },
  text_edu_year: {
    fontWeight: "500",
    color: "#333",
    fontSize: "1rem",
    fontFamily: "Sarabun, sans-serif",
  },
};
